// Libraries
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import React from 'react';

const pad = (number, places) => {
  if (places === 3) {
    if (number < 10) return `00${number}`;
    else if (number < 100) return `0${number}`;
  }
  return number > 9 ? number : `0${number}`;
};

const hms = (ms) => {
  const duration = moment.duration(ms);
  return `${Math.floor(duration.asHours())}:${pad(duration.minutes())}:${pad(
    duration.seconds()
  )}`;
};

const dhms = (ms) => {
  var result = '';
  const duration = moment.duration(ms);
  if (duration.days() > 0) result = `${Math.floor(duration.asDays())}d `;
  result += `${pad(duration.hours())}:${pad(duration.minutes())}:${pad(
    duration.seconds()
  )}`;
  return result;
};

const dhmsz = (ms) => {
  var result = '';
  const duration = moment.duration(ms);
  if (duration.days() > 0) result = `${Math.floor(duration.asDays())}d `;
  result += `${pad(duration.hours())}:${pad(duration.minutes())}:${pad(
    duration.seconds()
  )}.${pad(duration.milliseconds(), 3)}`;
  return result;
};

const humanReadableBytes = (bytes) => {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

function intToBinary(integer, bits) {
  // Convert integer to binary string
  let binaryString = integer.toString(2);

  // Pad the binary string with leading zeros to make it the specified number of bits
  binaryString = binaryString.padStart(bits, '0');

  // Insert ' symbol every 4 bits (nibble)
  let result = '';
  for (let i = 0; i < binaryString.length; i++) {
    result += binaryString[i];
    if ((i + 1) % 4 === 0 && i !== binaryString.length - 1) {
      result += "'";
      // insert new line symbol in the middle of the 64-bit number
      if (bits === 64 && i === 31) result += '\n';
    }
  }

  return result;
}

const FormatProp = (prop) => {
  if (prop.hasOwnProperty('v') && prop.v !== undefined) {
    if (
      [
        'bool',
        'float',
        'percentage',
        'time',
        'difftime',
        's64',
        's32',
        's16',
        's8',
        'u64',
        'u32',
        'u16',
        'u8',
        'bytes',
      ].indexOf(prop.type) < 0
    ) {
      return `type '${prop.type}' unknown`;
    }
    if (prop.type === 'bool') {
      return prop.v ? 'true' : 'false';
    } else if (prop.type === 'difftime') {
      if (prop.unit === 'dhms') {
        return dhms(prop.v);
      } else if (prop.unit === 'dhmsz') {
        return dhmsz(prop.v);
      } else if (prop.unit === 'hms') {
        return hms(prop.v);
      } else if (prop.unit === 'h') {
        return `${Math.floor(moment.duration(prop.v).asHours())} ${prop.unit}`;
      } else {
        return `${Math.floor(prop.v)}`;
      }
    } else if (prop.unit === 'ms') {
      return dhms(prop.v);
    } else if (prop.type === 'time') {
      return prop.v ? moment(prop.v).format('MMM D, HH:mm:ss') : '-';
    } else if (prop.unit === 'slider' && prop.type === 'percentage') {
      const afterDot = prop.afterDot || 1;
      return `${parseFloat(prop.v).toFixed(afterDot)} %`;
    } else if (prop.type === 'float' || prop.type === 'percentage') {
      const afterDot = prop.afterDot || 1;
      return `${parseFloat(prop.v).toFixed(afterDot)} ${prop?.unit || ''}`;
    } else if (prop.type === 'u8' && prop.unit === 'hex') {
      return `0x${parseInt(prop.v).toString(16).padStart(2, '0')}`;
    } else if (prop.type === 'u16' && prop.unit === 'hex') {
      return `0x${parseInt(prop.v).toString(16).padStart(4, '0')}`;
    } else if (prop.type === 'u32' && prop.unit === 'hex') {
      return `0x${parseInt(prop.v).toString(16).padStart(8, '0')}`;
    } else if (prop.type === 'u64' && prop.unit === 'hex') {
      return `0x${parseInt(prop.v).toString(16).padStart(16, '0')}`;
    } else if (prop.type === 'u8' && prop.unit === 'bin') {
      return `${intToBinary(parseInt(prop.v), 8)}`;
    } else if (prop.type === 'u16' && prop.unit === 'bin') {
      return `${intToBinary(parseInt(prop.v), 16)}`;
    } else if (prop.type === 'u32' && prop.unit === 'bin') {
      return `${intToBinary(parseInt(prop.v), 32)}`;
    } else if (prop.type === 'u64' && prop.unit === 'bin') {
      return `${intToBinary(parseInt(prop.v), 64)}`;
    } else if (prop.type === 'bytes') {
      if (prop.v.length <= 8) {
        return prop.v;
      }
      const firstThree = prop.v.toString().slice(0, 3);
      const lastThree = prop.v.toString().slice(-3);
      return `${firstThree}..${lastThree}`;
    } else if (prop.unit === 'ascii' && prop.type === 'u8') {
      return `${String.fromCharCode(prop.v)}`;
    } else if (prop.unit === 'h' && prop.type === 's64') {
      return humanReadableBytes(prop.v);
    } else {
      return `${Math.floor(prop.v)} ${prop?.unit || ''}`;
    }
  } else {
    return <CircularProgress size={15} />;
  }
};

export default FormatProp;
