import React, { useEffect, useState } from 'react';

// MUI components
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

// Store
import useStore from '../store/store';

export default function RawPage() {
  // Global state
  const setTitle = useStore((state) => state.setTitle);
  const rawNetworkUpdate = useStore((state) => state.rawNetworkUpdate);

  const [filters, setFilters] = useState({
    gateway: true,
    node: true,
    attachment: true,
    regExp: false,
  });

  const [regExp, setRegExp] = useState('');

  const handleFilterChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.checked,
    });
  };

  const handleRegExpChange = (event) => {
    setRegExp(event.target.value);
  };

  const wildcardToRegex = (wildcard) => {
    return new RegExp(
      '^' +
        wildcard.replaceAll(
          /[.*+?^=!:${}()|[\]/\\]/g,
          (s) =>
            s === '?'
              ? '.' // map ? to .
              : s === '*'
                ? '.*' // map * to .*
                : '\\' + s // escape other
        ) +
        '$'
    );
  };

  useEffect(() => {
    setTitle('Raw');
  }, [setTitle]);

  let result = '';
  let count = 0;
  for (let i = rawNetworkUpdate.length - 1; i >= 0; i--) {
    if (!rawNetworkUpdate[i]) continue;
    let isDataToDisplay = false;
    if (count >= 10) break;
    for (let j = 0; j < rawNetworkUpdate[i].length; j++) {
      const property = rawNetworkUpdate[i][j];
      if (!filters.gateway && property.id.startsWith('g.')) continue;
      if (!filters.node && property.id.startsWith('n.')) continue;
      if (!filters.attachment && property.id.startsWith('s.')) continue;
      if (
        filters.regExp &&
        regExp &&
        !wildcardToRegex(regExp).test(property.id)
      )
        continue;
      result += `${property.id}, ${property.t}, ${property.v}\n`;
      isDataToDisplay = true;
    }
    if (isDataToDisplay) {
      result +=
        '------------------------------------------------------------\n';
      count++;
    }
  }

  return (
    <Box>
      <Box>
        <FormControl component='fieldset' variant='standard'>
          <FormLabel component='legend'>Filters</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.gateway}
                  onChange={handleFilterChange}
                  name='gateway'
                />
              }
              label='Gateway'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.node}
                  onChange={handleFilterChange}
                  name='node'
                />
              }
              label='Node'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.attachment}
                  onChange={handleFilterChange}
                  name='attachment'
                />
              }
              label='Attachment'
            />
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.regExp}
                    onChange={handleFilterChange}
                    name='regExp'
                  />
                }
                label='RegExp'
              />
              <TextField
                id='regexp-textfield'
                label='RegExp'
                variant='outlined'
                disabled={!filters.regExp}
                size='small'
                value={regExp}
                onChange={handleRegExpChange}
              />
            </Box>
          </FormGroup>
        </FormControl>
      </Box>
      <pre>{result}</pre>
    </Box>
  );
}
