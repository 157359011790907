import React, { useEffect } from 'react'

// Store
import useStore from '../store/store'

// MUI components
import {
  makeStyles
} from '@material-ui/core'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

// Components
import LampGroupControls from '../components/LampGroupControls'
import Lamp from '../components/Lamp'

import { FormattedMessage } from "react-intl"

const LampGroup = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`lamp-group-${index}`}
      aria-labelledby={`lamp-group-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `lamp-group-${index}`,
    'aria-controls': `lamp-group-${index}`,
  };
}

export default function LampsPage() {
  // Styles
  const classes = useStyles()

  const setTitle = useStore((state) => state.setTitle)
  const [value, setValue] = React.useState(0)
  const nodes = useStore((state) => state.nodes)
  const networkStateFetched = useStore((state) => state.networkStateFetched)
  const networkStateProperties = useStore(
    (state) => state.networkStateProperties
  );

  const handleChange = (event, newValue) => {
    setValue(newValue)
  };

  useEffect(() => {
    setTitle("Lamps")
  }, [setTitle])

  const filterNodesBySensorModelId = (nodes, sensorModelId) =>
  {
    let lamps = []
    for (const node of nodes) {
      for (const sensor of node.sensors) {
        if (sensor.SensorModelId === sensorModelId) {
          lamps.push({
            node: node,
            sensorIndex: sensor.Index
          })
        }
      }
    }
    return lamps
  }

  const lamps = filterNodesBySensorModelId(nodes, 30)
  const groups = [{
    id: 0,
    label: <FormattedMessage defaultMessage="Default" id="lKv8ex" />,
    lamps: []
  }]
  if (networkStateFetched && networkStateProperties) {
    for (const lamp of lamps) {
      let sensor = lamp.node.sensors.find((s) => s.Index === lamp.sensorIndex)
      const group = networkStateProperties.find((p) => p.id === `s.${lamp.node.Uuid}.${sensor.Index}.groupID`)
      if (!group || !group.hasOwnProperty("v")) continue
      let id;
      id = parseInt(group.v);
      if (isNaN(id)) {
        console.error(`Invalid group ID: ${group.v}`);
        id = null;
      }
      if (!groups.some(g => g.id === id)) {
        groups.push({
          id: id,
          label: <FormattedMessage
            defaultMessage="Group {id}" id="DWSt2f"
            values={{ id: group.v }}
          />,
          lamps: []
        })
      }
      groups.find(g => g.id === id).lamps.push(lamp)
    }
  }

  return (
    <Box className={classes.root}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <LampGroupControls group={groups.filter(g => g.id === value)[0]}/>
        <Tabs value={value} onChange={handleChange} aria-label="lamps">
          {groups.map((group) => <Tab key={group.id} label={group.label} {...a11yProps(group.id)} />)}
        </Tabs>
      </Box>
      {groups.length > 0 ? (
        groups.map((group, index) => {
          return (
            <LampGroup key={group.id} value={value} index={index} className={classes.tab}>
              {group.lamps.map((lamp) => {
                const node = lamp.node
                const sensor = node.sensors.find((s) => s.Index === lamp.sensorIndex)
                return (
                  <Lamp
                    key={`${node.Uuid}-${lamp.sensorIndex}`}
                    className={classes.lampItem}
                    index={lamp.sensorIndex}
                    name={sensor.Name}
                    nodeUuid={node.Uuid}
                    nodeId={node.NodeId}
                    sensorModelId={sensor.SensorModelId}
                    nodeName={node.Name}
                  />
                )
              })}
            </LampGroup>
          )
        })
      ) : (
        <div>No groups available</div>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
  },
  tab: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: '40px 20px',
    flexWrap: 'wrap',
  },
  lampItem: {
    flex: '1 1 auto',
    margin: '5px',
  },
}))
