import UUID from 'uuid-js'
import Urls from '../consts/Urls'
import ErrorCodes from '../consts/ErrorCodes'
import api from './api'
import { jwtDecode } from 'jwt-decode'
import useStore from '../store/store'
import subscribe from '../actions/subscribe'

export default class WsClient {
  /** @public */
  constructor(aUrl) {
    this.theUrl = aUrl

    this.theIsConnected = false
    this.theClient = null
    this.theIsFullyInitialized = false

    this.theRetryCount = 0
    this.theRetryTimeout = null

    // Create maps for storing callbacks and a request queue.
    this.theRequestMap = {}
    this.theRequestQueue = []

    // Create map for subscriptions.
    this.theSubscriptionMap = {}
    this.theSubscriptionCallbacks = []

    // Reconnect callbacks.
    this.theReconnectCallbacks = []
    this.theShouldRunReconnectCallbacks = false
    this.theReconnectCallbacks.push(() => {subscribe()})

    // Reload callbacks.
    this.theReloadCallbacks = []
  }

  clearRetryTimeout() {
    if (this.theRetryTimeout) {
      clearTimeout(this.theRetryTimeout)
      this.theRetryTimeout = null
    }
  }

  /** @private */
  closeClient(aShouldSaveQueue = false) {
    if (this.theClient) {
      console.log('Closing WebSocket.')

      if (aShouldSaveQueue) {
        this.saveCurrentRequests()
      } else {
        this.theIsFullyInitialized = false
      }

      this.theIsConnected = false

      // Unbind the callbacks.
      this.theClient.onopen = null
      this.theClient.onclose = null
      this.theClient.onmessage = null
      this.theClient.onerror = null

      // Close and delete the client.
      this.theClient.close()
      this.theClient = null
    }
  }

  /** @private */
  connect() {
    const setFormError = useStore.getState().setFormError

    if (this.theIsConnected) {
      throw new Error('Already connected.')
    }

    this.clearRetryTimeout()
    this.theIsConnected = false
    this.closeClient()

    // Create WebSocket instance.
    try {
      this.theClient = new WebSocket(Urls.baseWsUrl)
    } catch (error) {
      console.error(error)
      setFormError(ErrorCodes.failed_to_create_websocket)
    }
    console.info('Connecting to the server via WebSocket.')

    // Bind the callbacks.
    this.theClient.onopen = this.onOpen
    this.theClient.onclose = this.onClose
    this.theClient.onmessage = this.onMessage
    this.theClient.onerror = this.onError
  }

  /** @private */
  onOpen = async () => {
    console.info('Connected to the server via WebSocket.')

    if (this.theIsFullyInitialized === false) {
      this.theIsFullyInitialized = true
    }

    // If reconnected.
    if (this.theRetryCount > 0 || this.theShouldRunReconnectCallbacks) {
      // Sync profile and dictionaries.
      for (let i = 0; i < this.theReconnectCallbacks.length; i++) {
        this.theReconnectCallbacks[i]()
      }
      this.theShouldRunReconnectCallbacks = false

      // Inform components that they should be reloaded when focused.
      for (let i = 0; i < this.theReloadCallbacks.length; i++) {
        this.theReloadCallbacks[i]()
      }

      // Restart subscriptions.
      for (let i = 0; i < this.theSubscriptionCallbacks.length; i++) {
        this.theSubscriptionCallbacks[i]()
      }
    }

    this.theRetryCount = 0
    this.theIsConnected = true

    if (this.theRequestQueue.length > 0) {
      console.log('Running queued requests!')
      const token = await getToken()
      for (const { config, callback, params } of this.theRequestQueue) {
        // Supply access token (it might have changed).
        config.authorization = `Bearer ${token}`
        this.send(config, callback, params)
      }

      // Clear the queue.
      this.theRequestQueue.length = 0
    }
  }

  /** @private */
  onClose = (e) => {
    if (this.theIsConnected) {
      this.theIsConnected = false

      console.warn('Lost WebSocket connection with the server. Reconnecting...')
      this.theRetryCount++
      this.connect()
    }
  }

  /** @private */
  onError = (message, url, line, column, e) => {
    const setFormError = useStore.getState().setFormError

    this.theIsConnected = false

    // Put all failed requests into the queue.
    this.saveCurrentRequests()

    // Try to reconnect.
    this.theRetryCount++
    let time
    if (this.theRetryCount < 5) {
      time = this.theRetryCount * 1000
    } else if (this.theRetryCount < 10) {
      time = this.theRetryCount * 2000
    } else if (this.theRetryCount < 20) {
      time = this.theRetryCount * 3000
    } else {
      time = 60000
    }

    console.warn(
      `Error during communication with the server via WebSocket. Will retry in ${
        time / 1000
      } second(s)...`
    )
    console.log(message, url, line, column, e)
    if (this.theRetryCount > 1)
      setFormError(ErrorCodes.rpc_communication_error)

    this.theRetryTimeout = setTimeout(this.connect.bind(this), time)
  }

  /** @private */
  onMessage = (e) => {
    try {
      // Parse received data.
      const data = JSON.parse(e.data)
      const { body, id, stream } = data

      // Detect if error.
      if (body.errCode && body.errCode === ErrorCodes.invalid_token) {
        console.warn(`Received error: '${body.errCode}'!`)
        this.refreshToken()
      } else if (body.errCode && body.errCode === ErrorCodes.auth_failed) {
        console.warn(`Received error: '${body.errCode}'!`)
        // Close the WebSocket client.
        this.closeClient()

        // Remove stored tokens and data.
        cleanUp()

        // Navigate to AuthScreen.
        // navigate('AuthWelcome')
      } else {
        if (id) {
          // Read request details from map.
          const { callback, params } = this.theRequestMap[id]

          if (callback) {
            // Call the provided callback.
            callback(body, params)

            // Clean the map.
            delete this.theRequestMap[id]
          }
        } else if (
          stream &&
          typeof stream === 'string' &&
          this.theSubscriptionMap[stream]
        ) {
          // Read request details from map.
          const { callback } = this.theSubscriptionMap[stream]

          if (callback) {
            // Call the provided callback.
            callback(body)
          }
        }
      }
    } catch (error) {
      console.warn(`Failed to handle incoming data: ${e.data}`, error)
    }
  }

  /** @public */
  send = (config, callback, params = {}) => {
    if (this.theIsConnected) {
      if (params.subscription) {
        // Check if cancelling subscription.
        if (params.cancel) {
          delete this.theSubscriptionMap[params.subscription]
        } else {
          // Remember the callback in subscriptionMap. Callback is called in onMessage.
          this.theSubscriptionMap[params.subscription] = { config, callback }
        }

        // Clear params.
        delete params.subscription
        delete params.cancel

        // Remember the callback in requestMap too, as the subscription related
        //  requests return success or errCode.
        // Callback is called in onMessage.
        this.theRequestMap[config.id] = { config, callback, params }
      } else {
        // Remember the callback in requestMap. Callback is called in onMessage.
        this.theRequestMap[config.id] = { config, callback, params }
      }

      console.log(`Calling ${config.endpoint}`)

      // Send the request.
      this.theClient.send(JSON.stringify(config))
    } else {
      // If WebSocket is not connected, queue the request.
      this.theRequestQueue.push({
        config,
        callback,
        params,
      })
    }
  }

  /** @private */
  saveCurrentRequests = () => {
    console.warn('Moving requests from map to queue.')
    for (const key in this.theRequestMap) {
      const request = this.theRequestMap[key]
      const { callback, params, config } = request
      // Skip dictionary changes, user profile update and subscription request as they're handled separately.
      if (
        config &&
        config.endpoint !== '/dictionary/get-changes' &&
        config.endpoint !== '/user/get-profile' &&
        config.endpoint.indexOf('subscribe') === -1
      ) {
        console.log(`${config.endpoint} moved to the queue!`)
        this.theRequestQueue.push({
          callback,
          params,
          config: { ...config, id: UUID.create().toString() },
        })
      }
    }

    // Clean the request map.
    this.theRequestMap = {}
  }

  /** @public */
  isConnected = () => {
    return this.theIsConnected
  }

  /** @public */
  isFullyInitialized = () => {
    return this.theIsFullyInitialized
  }

  /** @public */
  addReconnectCallback = (callbacks = []) => {
    this.theReconnectCallbacks = callbacks
  }

  /** @public */
  addReloadCallbacks = (callbacks = []) => {
    this.theReloadCallbacks = callbacks
  }

  /** @public */
  addSubscriptionCallbacks = (callbacks = []) => {
    this.theSubscriptionCallbacks = callbacks
  }

  /** @public */
  setShouldReconnectCallbacks = (value) => {
    this.theShouldRunReconnectCallbacks = value
  }

  /** @private */
  refreshToken = async () => {
    // Since no request will succeed till token is refreshed
    // we're closing the client.
    this.closeClient(true)
    this.setShouldReconnectCallbacks(true)

    const refreshToken = localStorage.getItem('refreshToken')

    if (refreshToken) {
      // Check if refresh token is still valid.
      // Decode refreshToken.
      const decodedRefreshToken = jwtDecode(refreshToken)

      // If refresh token is not expired then refresh main token.
      const secondsSinceEpoch = Math.floor(Date.now() / 1000)
      if (decodedRefreshToken.exp < secondsSinceEpoch) {
        // Remove stored tokens and data.
        cleanUp()

        // Navigate to AuthScreen.
        // navigate('AuthWelcome')
      }

      // Update Axios header to use refresh token instead.
      api.defaults.headers.common.Authorization = `Bearer ${refreshToken}`

      console.log('Fetching new token...')
      return api
        .post('/security/refresh-access-token')
        .then((refreshTokenResponse) => {
          const { success, accessToken, errCode } = refreshTokenResponse.data

          if (success && accessToken && !errCode) {
            console.log('Received new token.')

            // Update Axios header to use accessToken instead of refresh token.
            api.defaults.headers.common.Authorization = `Bearer ${accessToken}`

            // Store new token.
            localStorage.setItem('accessToken', accessToken)

            // Restart the connection.
            this.connect()
          } else {
            console.log('Failed to refresh access token.')
            cleanUp()
          }
        })
        .catch(() => {
          console.log('Failed to refresh access token.')
          cleanUp()
        })
    } else {
      console.log('Refresh token not available!')
      cleanUp()
    }
  }
}

export const ws = new WsClient(Urls.baseWsUrl)
// This is a wrapper around ws.send().
export const sendRequest = async (config, callback, params = {}) => {
  // Read token before each request since it could change (due to refresh).
  const token = await getToken()

  // Expand the config with general params.]
  config.authorization = `Bearer ${token}`
  config.contentType = 'application/json'
  config.id = UUID.create().toString()

  // Pass the params to WebSocket client and send the request.
  ws.send(config, callback, params)
}

// Helper function to retrive token.
const getToken = async () => {
  const token = localStorage.getItem('accessToken')
  return token
}

const cleanUp = () => {
  // Remove the headers.
  api.defaults.headers.common.Authorization = null

  // Clean storage.
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('permissions')

  // Clean request map and queue.
  this.theRequestMap = {}
  this.theRequestQueue = []

  // Clean subscriptions map.
  this.theSubscriptionMap = {}
}
