import React, { useCallback, useState, useEffect } from 'react'
import { produce } from 'immer'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

// MUI components
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
} from '@material-ui/core'

// MUI icons
import VisibilityIcon from '@mui/icons-material/Visibility'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'

// Components
import SensorHeader from './SensorHeader'
import SensorMenu from './SensorMenu'
import Prop from './Prop'

// Helpers
import formatSerial from '../helpers/formatSerial'
import getSensorModel from '../helpers/getSensorModel'
import isPermissionGranted from '../helpers/isPermissionGranted.js'

// Consts
import Permissions from '../consts/Permissions'

// Store
import useStore from '../store/store'

// Global Google
const google = window.google
const googleMapsLibraries = ['geometry', 'core']

const Sensor = ({ index, name, nodeUuid, nodeId, sensorModelId, nodeName }) => {
  // Global State
  const networkStateFetched = useStore((state) => state.networkStateFetched)
  const networkStateProperties = useStore(
    (state) => state.networkStateProperties
  )
  const sensorModel = getSensorModel(sensorModelId)
  const gatewayUuid = useStore((state) => state.gatewayUuid)
  const secondsSinceLastUpdate = useStore(
    (state) => state.secondsSinceLastUpdate
  )
  const loadedSensorPropertiesCount = useStore(
    (state) => state.loadedSensorPropertiesCount
  )
  const setLoadedSensorPropertiesCount = useStore(
    (state) => state.setLoadedSensorPropertiesCount
  )

  // Local state
  const [editingProperties, setEditingProperties] = useState([])
  const [mapDialogOpen, setMapDialogOpen] = useState(false)
  const [latitude, setLatitude] = useState(0.0)
  const [longitude, setLongitude] = useState(0.0)
  const [latitudeHem, setLatitudeHem] = useState('78') // N
  const [longitudeHem, setLongitudeHem] = useState('69') // E
  const [map, setMap] = useState(null)
  const [mapZoom, setMapZoom] = useState(10)
  const [mapCenter, setMapCenter] = useState({
    lat: 54.5355729,
    lng: 18.5297084,
  })
  const [gatewayLatitude, setGatewayLatitude] = useState(0.0)
  const [gatewayLongitude, setGatewayLongitude] = useState(0.0)
  const [distanceFromGateway, setDistanceFromGateway] = useState(0.0)

  const classes = useStyles({
    secondsSinceLastUpdate,
    loadedSensorPropertiesCount,
  })

  // useEffect
  useEffect(() => {
    if (networkStateFetched && networkStateProperties) {
      const gatewayLatitudeProp = networkStateProperties.find(
        (p) => p.id === 'g.' + gatewayUuid + '.latitude'
      )
      if (gatewayLatitudeProp) {
        setGatewayLatitude(gatewayLatitudeProp.v)
      }

      const gatewayLongitudeProp = networkStateProperties.find(
        (p) => p.id === 'g.' + gatewayUuid + '.longitude'
      )
      if (gatewayLongitudeProp) {
        setGatewayLongitude(gatewayLongitudeProp.v)
      }

      if (
        google &&
        google.maps &&
        google.maps.geometry &&
        google.maps.geometry.spherical
      ) {
        try {
          const distance =
            google.maps.geometry.spherical.computeDistanceBetween(
              { lat: latitude, lng: longitude },
              { lat: gatewayLatitude, lng: gatewayLongitude }
            )
          setDistanceFromGateway(distance)
        } catch (e) {
          console.log(e)
        }
      }
    }
  }, [
    networkStateFetched,
    networkStateProperties,
    gatewayUuid,
    gatewayLatitude,
    gatewayLongitude,
    latitude,
    longitude,
  ])

  const onMapLoad = useCallback(function callback(map) {
    var zoom = localStorage.getItem('mapZoom')
    var mapCenter = localStorage.getItem('mapCenter')
    if (zoom) setMapZoom(JSON.parse(zoom))
    if (mapCenter) setMapCenter(JSON.parse(mapCenter))
    setMap(map)
  }, [])

  const handleMapIconClick = (event) => {
    setMapDialogOpen(true)
  }

  const handleMapDialogClose = () => {
    setMapDialogOpen(false)
  }

  const handleMapZoomChange = () => {
    if (map) {
      setMapZoom(map.zoom)
      localStorage.setItem('mapZoom', JSON.stringify(map.zoom))
    }
  }

  const handleMapCenterChange = () => {
    if (map) {
      localStorage.setItem('mapCenter', JSON.stringify(map.center))
    }
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCyqbeKcMZdvLXCy68KVg_xSfepurAdyJY',
    libraries: googleMapsLibraries,
  })

  const onMapUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const containerStyle = {
    width: 0.8 * window.innerWidth,
    height: 0.8 * window.innerHeight,
  }

  const showProperties = () => {
    const handleEditButtonClick = (prop) => {
      if (!editingProperties.includes(prop.name)) {
        const editing = produce(editingProperties, (draft) => {
          draft.push(prop.name)
        })
        setEditingProperties(editing)
      } else {
        const editing = editingProperties.filter((name) => name !== prop.name)
        setEditingProperties(editing)
      }
    }

    if (sensorModel) {
      let properties = JSON.parse(JSON.stringify(sensorModel.Properties))
      if (networkStateFetched && networkStateProperties) {
        properties.forEach((property, i) => {
          const matchingProp = networkStateProperties.find(
            (p) =>
              p.id === 's.' + nodeUuid + '.' + index + '.' + properties[i].name
          )
          if (matchingProp) {
            properties[i] = { ...properties[i], ...matchingProp }
            if (
              properties[i].name === 'latitude' &&
              properties[i].v !== latitude
            ) {
              setLatitude(properties[i].v)
            } else if (
              properties[i].name === 'longitude' &&
              properties[i].v !== longitude
            ) {
              setLongitude(properties[i].v)
            } else if (
              properties[i].name === 'latitudeHem' &&
              properties[i].v !== latitudeHem
            ) {
              setLatitudeHem(properties[i].v)
            } else if (
              properties[i].name === 'longitudeHem' &&
              properties[i].v !== longitudeHem
            ) {
              setLongitudeHem(properties[i].v)
            }
          }
        })
      } else {
        properties = properties.map((p) => ({ ...p, v: undefined }))
      }

      if (loadedSensorPropertiesCount === 0) {
        setLoadedSensorPropertiesCount(
          properties.filter(
            (prop) => prop.hasOwnProperty('v') && prop.v !== undefined
          ).length
        )
      }

      return properties
        .filter(
          (prop) =>
            isPermissionGranted(Permissions.show_hidden_properties) ||
            !(prop.hasOwnProperty('hidden') && prop.hidden === true)
        )
        .map((prop) => (
          <Prop
            prop={prop}
            key={prop.name}
            editingProperties={editingProperties}
            nodeUuid={nodeUuid}
            index={index}
            handleEditButtonClick={handleEditButtonClick}
          ></Prop>
        ))
    }
  }

  const displayDistance = gatewayLatitude !== 0.0 || gatewayLongitude !== 0.0

  let gatewayMarker = null
  if (displayDistance && google && google.maps && google.maps.Point) {
    gatewayMarker = {
      path: 'M360-440h80v-110h80v110h80v-190l-120-80-120 80v190Zm120 254q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z',
      fillColor: 'blue',
      fillOpacity: 0.6,
      strokeWeight: 0,
      rotation: 0,
      scale: 0.05,
      anchor: new google.maps.Point(500, -100),
    }
  }

  return (
    <>
      <Box className={classes.root}>
        {!networkStateFetched && (
          <IconButton
            disabled={true}
            style={{ pointerEvents: 'none' }}
            color='primary'
          >
            <DoDisturbIcon />
          </IconButton>
        )}
        <SensorHeader
          name={name}
          tooltip={sensorModel && sensorModel.Name}
          caption={`serial: ${formatSerial(nodeUuid)}.${index}`}
          subtitle={nodeName}
          icon={
            // 18 - VEHICLE_MONITOR_ST01
            // 13 - GPS, reports latitude and longitude in google api format
            // 28 - VEHICLE_MONITOR_ST02, reports latitude and longitude in google api format
            (sensorModelId === 13 ||
              sensorModelId === 18 ||
              sensorModelId === 28) && (
              <div className={classes.eyeIcon}>
                <IconButton
                  disabled={!isLoaded}
                  color='primary'
                  onClick={handleMapIconClick}
                >
                  <VisibilityIcon />
                </IconButton>
              </div>
            )
          }
          menuComponent={
            <SensorMenu index={index} name={name} nodeId={nodeId} />
          }
        />
        {showProperties()}
      </Box>
      <Dialog
        open={mapDialogOpen}
        aria-labelledby='rename-dialog-title'
        onCancel={handleMapDialogClose}
        maxWidth={false} // Set maxWidth to false
      >
        <DialogContent>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={mapZoom}
            onZoomChanged={handleMapZoomChange}
            onCenterChanged={handleMapCenterChange}
            onLoad={onMapLoad}
            onUnmount={onMapUnmount}
          >
            <Marker
              position={{
                lat: latitudeHem === '83' ? -latitude : latitude, // 83 - S
                lng: longitudeHem === '87' ? -longitude : longitude, // 87 - W
              }}
            />
            {gatewayMarker && (
              <Marker
                position={{
                  lat: gatewayLatitude,
                  lng: gatewayLongitude,
                }}
                icon={gatewayMarker}
              />
            )}
          </GoogleMap>
        </DialogContent>
        <DialogActions>
          {displayDistance && (
            <div style={{ paddingLeft: '16px' }}>
              Distance from gateway:{' '}
              {(Math.round(distanceFromGateway * 100) / 100).toFixed(2)} m
            </div>
          )}

          <div style={{ flex: '1 0 0' }} />
          <Button onClick={handleMapDialogClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Sensor

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    // Figma
    display: 'flex',
    width: '486px',
    minWidth: '300px',
    maxWidth: '486px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '12px',
    background:
      props.loadedSensorPropertiesCount > 0 && props.secondsSinceLastUpdate > 10
        ? '#FF7F7F'
        : 'white',
    boxShadow: '2px 4px 9px 0px rgba(0, 0, 0, 0.06)',
    marginBottom: '25px',
    marginRight: '21px',
  }),
  editIcon: {
    display: 'flex',
    height: '10px',
  },
  monitoring: {
    // Figma
    display: 'flex',
    minHeight: '46px',
    padding: '14px 28px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    flexDirection: 'row',
  },
  value: {
    textAlign: 'right',
  },
  name: {
    display: 'flex',
    textAlign: 'right',
  },
  valueContainer: {
    width: '100',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  eyeIcon: {
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent',
    },
  },
}))
