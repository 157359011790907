import React, { useState } from 'react'
import { produce } from 'immer'

// MUI components
import {
  Box,
  IconButton,
  makeStyles,
} from '@material-ui/core'

// MUI icons
import DoDisturbIcon from '@mui/icons-material/DoDisturb'

// Components
import SensorHeader from './SensorHeader'
import SensorMenu from './SensorMenu'
import Prop from './Prop'

// Helpers
import getSensorModel from '../helpers/getSensorModel'
import isPermissionGranted from '../helpers/isPermissionGranted.js'

// Consts
import Permissions from '../consts/Permissions'

// Store
import useStore from '../store/store'

const Sensor = ({ index, name, nodeUuid, nodeId, sensorModelId, nodeName }) => {
  // Global State
  const networkStateFetched = useStore((state) => state.networkStateFetched)
  const networkStateProperties = useStore(
    (state) => state.networkStateProperties
  )
  const sensorModel = getSensorModel(sensorModelId)
  const secondsSinceLastUpdate = useStore(
    (state) => state.secondsSinceLastUpdate
  )
  const loadedSensorPropertiesCount = useStore(
    (state) => state.loadedSensorPropertiesCount
  )
  const setLoadedSensorPropertiesCount = useStore(
    (state) => state.setLoadedSensorPropertiesCount
  )

  // Local state
  const [editingProperties, setEditingProperties] = useState([])

  const classes = useStyles({
    secondsSinceLastUpdate,
    loadedSensorPropertiesCount,
  })

  const showProperties = () => {
    const handleEditButtonClick = (prop) => {
      if (!editingProperties.includes(prop.name)) {
        const editing = produce(editingProperties, (draft) => {
          draft.push(prop.name)
        })
        setEditingProperties(editing)
      } else {
        const editing = editingProperties.filter((name) => name !== prop.name)
        setEditingProperties(editing)
      }
    }

    if (sensorModel) {
      let properties = JSON.parse(JSON.stringify(sensorModel.Properties))
      properties = properties.filter((p) => [
        "on",
        "switch",
        "lightOutput",
        "fadeTime",
        "failure",
        "activeEnergy",
        "activePower",
      ].indexOf(p.name) >= 0)

      if (networkStateFetched && networkStateProperties) {
        properties.forEach((property, i) => {
          const matchingProp = networkStateProperties.find(
            (p) =>
              p.id === 's.' + nodeUuid + '.' + index + '.' + properties[i].name
          )
          if (matchingProp) {
            properties[i] = { ...properties[i], ...matchingProp }
          }
        })
      } else {
        properties = properties.map((p) => ({ ...p, v: undefined }))
      }

      if (loadedSensorPropertiesCount === 0) {
        setLoadedSensorPropertiesCount(
          properties.filter(
            (prop) => prop.hasOwnProperty('v') && prop.v !== undefined
          ).length
        )
      }

      return properties
        .filter(
          (prop) =>
            isPermissionGranted(Permissions.show_hidden_properties) ||
            !(prop.hasOwnProperty('hidden') && prop.hidden === true)
        )
        .map((prop) => (
          <Prop
            prop={prop}
            key={prop.name}
            editingProperties={editingProperties}
            nodeUuid={nodeUuid}
            index={index}
            handleEditButtonClick={handleEditButtonClick}
          ></Prop>
        ))
    }
  }

  return (
    <>
      <Box className={classes.root}>
        {!networkStateFetched && (
          <IconButton
            disabled={true}
            style={{ pointerEvents: 'none' }}
            color='primary'
          >
            <DoDisturbIcon />
          </IconButton>
        )}
        <SensorHeader
          name={name}
          tooltip={sensorModel && sensorModel.Name}
          caption={`serial: ${nodeUuid.slice(8)}`}
          subtitle={nodeName}
          menuComponent={
            <SensorMenu index={index} name={name} nodeId={nodeId} />
          }
        />
        {showProperties()}
      </Box>
    </>
  )
}

export default Sensor

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    // Figma
    display: 'flex',
    width: '486px',
    minWidth: '300px',
    maxWidth: '486px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '12px',
    background:
      props.loadedSensorPropertiesCount > 0 && props.secondsSinceLastUpdate > 10
        ? '#FF7F7F'
        : 'white',
    boxShadow: '2px 4px 9px 0px rgba(0, 0, 0, 0.06)',
    marginBottom: '25px',
    marginRight: '21px',
  }),
  editIcon: {
    display: 'flex',
    height: '10px',
  },
  monitoring: {
    // Figma
    display: 'flex',
    minHeight: '46px',
    padding: '14px 28px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    flexDirection: 'row',
  },
  value: {
    textAlign: 'right',
  },
  name: {
    display: 'flex',
    textAlign: 'right',
  },
  valueContainer: {
    width: '100',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  eyeIcon: {
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent',
    },
  },
}))
