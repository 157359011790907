import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'
import MuiInput from '@mui/material/Input'
import Switch from '@mui/material/Switch'
import Stack from '@mui/material/Stack'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import setSensorPropertyValue from '../actions/setSensorPropertyValue'

const LampGroupControls = ({group}) => {
  const [checked, setChecked] = useState(false)

  const handleModifyGroupChange = (event) => {
    setChecked(event.target.checked)
  }

  const handleSwitchChange = (event) => {
    for (const lamp of group.lamps) {
      setSensorPropertyValue(lamp.node.Uuid, lamp.sensorIndex, 'switch', Boolean(event.target.checked))
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
        width: '100%',
        flexWrap: 'wrap',
      }}
    >
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleModifyGroupChange} />}
        label="Modify group"
        sx={{
          margin: 0,
          alignItems: 'center',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
          flex: 1,
          minWidth: '200px',
        }}
      >
        <Typography
          id="input-slider"
          gutterBottom
          sx={{
            margin: 0,
            lineHeight: 1.5,
            color: checked ? 'text.primary' : 'text.disabled',
          }}
        >
          Brightness
        </Typography>
        <InputSlider disabled={!checked} sx={{ flex: 1 }} group={group} />
      </Box>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: 'center',
          minWidth: '150px',
        }}
      >
        <Typography sx={{ color: checked ? 'text.primary' : 'text.disabled' }}>Off</Typography>
        <Switch
          defaultChecked
          inputProps={{ 'aria-label': 'ant design' }}
          disabled={!checked}
          group={group}
          onChange={handleSwitchChange}
        />
        <Typography sx={{ color: checked ? 'text.primary' : 'text.disabled' }}>On</Typography>
      </Stack>
    </Box>
  )
}

const InputSlider = ({ disabled, group }) => {
  const [value, setValue] = useState(50)

  const handleSliderChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleSliderChangeCommitted = (event, newValue) => {
    for (const lamp of group.lamps) {
      setSensorPropertyValue(lamp.node.Uuid, lamp.sensorIndex, 'lightOutput', newValue)
    }
    setValue(newValue)
  }

  const handleInputChange = (event) => {
    for (const lamp of group.lamps) {
      setSensorPropertyValue(lamp.node.Uuid, lamp.sensorIndex, 'lightOutput', event.target.value)
    }
    setValue(event.target.value === '' ? 0 : Number(event.target.value))
  }

  const handleBlur = () => {
    if (value < 0) {
      setValue(0)
    } else if (value > 100) {
      setValue(100)
    }
  }

  return (
    <Box sx={{ width: 250 }}>
      <Grid container spacing={2} sx={{ alignItems: 'center' }}>
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderChangeCommitted}
            aria-labelledby="input-slider"
            disabled={disabled}
          />
        </Grid>
        <Grid item>
          <MuiInput
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 10,
              min: 0,
              max: 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default LampGroupControls
