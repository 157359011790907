import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi'
import LightIcon from '@mui/icons-material/Light';
import SpeedIcon from '@material-ui/icons/Speed'
import InputIcon from '@material-ui/icons/Input'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import FeedIcon from '@mui/icons-material/Feed';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

// Store
import useStore from '../store/store';

// Helpers
import isPermissionGranted from '../helpers/isPermissionGranted.js';

// Consts
import Permissions from '../consts/Permissions';

import { FormattedMessage } from "react-intl"

const MainMenuItems = ({ activePage }) => {
  // Styles
  const classes = useStyles();

  const title = useStore((state) => state.title);

  return (
    <div>
      {isPermissionGranted(Permissions.gateway_screen) && (
        <ListItem button component={Link} to='/' replace>
          <ListItemIcon className={title === 'Gateway' ? classes.active : null}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage defaultMessage="Gateway" id="pMcQck" />}
            classes={{ primary: title === 'Gateway' ? classes.active : null }}
          />
        </ListItem>)}
      {isPermissionGranted(Permissions.nodes_screen) && (
        <ListItem button component={Link} to='/nodes' replace>
          <ListItemIcon className={title === 'Nodes' ? classes.active : null}>
            <SettingsInputHdmiIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage defaultMessage="Nodes" id="Cjr0yv" />}
            classes={{ primary: title === 'Nodes' ? classes.active : null }}
          />
        </ListItem>
      )}
      {isPermissionGranted(Permissions.attachments_screen) && (
        <ListItem button component={Link} to='/sensors' replace>
          <ListItemIcon
            className={title === 'Attachments' ? classes.active : null}
          >
            <SpeedIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage defaultMessage="Attachments" id="Vlx13n" />}
            classes={{ primary: title === 'Attachments' ? classes.active : null }}
          />
        </ListItem>
      )}
      {isPermissionGranted(Permissions.logic_screen) && (
        <ListItem button component={Link} to='/logic' replace>
          <ListItemIcon className={title === 'Logic' ? classes.active : null}>
            <InputIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage defaultMessage="Logic" id="HhyGLP" />}
            classes={{ primary: title === 'Logic' ? classes.active : null }}
          />
        </ListItem>
      )}
      {isPermissionGranted(Permissions.dashboard) && (
        <ListItem button component={Link} to='/dashboard' replace>
          <ListItemIcon
            className={title === 'Dashboard' ? classes.active : null}
          >
            <AnalyticsIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage defaultMessage="Dashboard" id="hzSNj4" />}
            classes={{ primary: title === 'Dashboard' ? classes.active : null }}
          />
        </ListItem>
      )}
      {isPermissionGranted(Permissions.raw) && (
        <ListItem button component={Link} to='/raw' replace>
          <ListItemIcon className={title === 'Raw' ? classes.active : null}>
            <FeedIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage defaultMessage="Raw" id="nV0wNv" />}
            classes={{ primary: title === 'Raw' ? classes.active : null }}
          />
        </ListItem>
      )}
      {isPermissionGranted(Permissions.lamps_screen) && (
        <ListItem button component={Link} to='/lamps' replace>
          <ListItemIcon className={title === 'Lamps' ? classes.active : null}>
            <LightIcon />
          </ListItemIcon>
          <ListItemText
            primary={<FormattedMessage defaultMessage="Lamps" id="uZ5Fww" />}
            classes={{ primary: title === "Lamps" ? classes.active : null }}
          />
        </ListItem>
      )}
    </div>
  );
};

export default MainMenuItems

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}))
