import { create } from 'zustand'
import { produce } from 'immer'

export const useStore = create((set) => ({
  loading: false,
  setLoading: (loading) =>
    set((state) => ({
      ...state,
      loading,
    })),
  accessToken: null,
  setAccessToken: (accessToken) =>
    set((state) => ({
      ...state,
      accessToken,
    })),
  refreshToken: null,
  setRefreshToken: (refreshToken) =>
    set((state) => ({
      ...state,
      refreshToken,
    })),
  formError: null,
  setFormError: (formError) =>
    set((state) => ({
      ...state,
      formError,
    })),
  successMessage: null,
  setSuccessMessage: (successMessage) =>
    set((state) => ({
      ...state,
      successMessage,
    })),
  title: '',
  setTitle: (title) =>
    set((state) => ({
      ...state,
      title,
    })),
  gatewayModels: [],
  setGatewayModels: (gatewayModels) =>
    set((state) => ({
      ...state,
      gatewayModels,
    })),
  nodeModels: [],
  setNodeModels: (nodeModels) =>
    set((state) => ({
      ...state,
      nodeModels,
    })),
  gateways: [],
  setGateways: (gateways) =>
    set((state) => ({
      ...state,
      gateways,
    })),
  nodes: [],
  setNodes: (nodes) =>
    set((state) => ({
      ...state,
      nodes,
    })),
  sensorModels: [],
  setSensorModels: (sensorModels) =>
    set((state) => ({
      ...state,
      sensorModels,
    })),
  networkStructureFetched: false,
  setNetworkStructureFetched: (networkStructureFetched) =>
    set((state) => ({
      ...state,
      networkStructureFetched,
    })),
  networkStateCounter: 0,
  setNetworkStateCounter: (counter) =>
    set(() => ({ networkStateCounter: counter })),
  networkStateProperties: [],
  setNetworkStateProperties: (properties) =>
    set(
      produce((state) => {
        if (!properties) return;
        for (const property of properties) {
          if (!property || !property.id) continue;
          if (!property.v) property.v = 0;
          const existingProperty = state.networkStateProperties.find(
            (p) => p.id === property.id
          );
          if (existingProperty) {
            if (
              property.t >= existingProperty.t ||
              existingProperty.t === undefined
            ) {
              existingProperty.v = property.v;
              existingProperty.t = property.t;
            }
          } else state.networkStateProperties.push(property);
        }
      })
    ),
  networkStateFetched: false,
  setNetworkStateFetched: (value) =>
    set(
      produce((state) => {
        state.networkStateFetched = value;
      })
    ),
  gatewayUuid: null,
  setGatewayUuid: (uuid) =>
    set((state) => ({
      ...state,
      gatewayUuid: uuid,
    })),
  gatewayStatus: '',
  setGatewayStatus: (status) =>
    set((state) => ({
      ...state,
      gatewayStatus: status,
    })),
  lastUpdateTime: new Date(),
  setLastUpdateTime: (time) =>
    set((state) => ({
      ...state,
      lastUpdateTime: time,
    })),
  secondsSinceLastUpdate: 0,
  setSecondsSinceLastUpdate: (secondsSinceLastUpdate) =>
    set((state) => ({
      ...state,
      secondsSinceLastUpdate,
    })),
  isAddGatewayFormVisible: false,
  setIsAddGatewayFormVisible: (isAddGatewayFormVisible) =>
    set((state) => ({
      ...state,
      isAddGatewayFormVisible,
    })),
  isAddNodeFormVisible: false,
  setIsAddNodeFormVisible: (isAddNodeFormVisible) =>
    set((state) => ({
      ...state,
      isAddNodeFormVisible,
    })),
  isAddLogicFormVisible: false,
  setIsAddLogicFormVisible: (isAddLogicFormVisible) =>
    set((state) => ({
      ...state,
      isAddLogicFormVisible,
    })),
  logicToUpdate: null,
  setLogicToUpdate: (logicToUpdate) =>
    set((state) => ({
      ...state,
      logicToUpdate,
    })),
  countries: [],
  setCountries: (countries) => set((state) => ({ ...state, countries })),
  logics: null,
  setLogics: (logics) => set((state) => ({ ...state, logics })),
  dataPanels: null,
  setDataPanels: (dataPanels) =>
    set(produce((state) => ({ ...state, dataPanels }))),
  updateDataPanel: (payload) =>
    set(
      produce((draft) => {
        const dataPanel = draft.dataPanels.find(
          (el) => el.DataPanelId === payload.dataPanelId
        );
        dataPanel.Configuration = payload.configuration;
      })
    ),
  APIKey: '',
  setAPIKey: (APIKey) =>
    set((state) => ({
      ...state,
      APIKey,
    })),
  loadedGatewayPropertiesCount: 0,
  setLoadedGatewayPropertiesCount: (loadedGatewayPropertiesCount) =>
    set((state) => ({
      ...state,
      loadedGatewayPropertiesCount,
    })),
  loadedNodePropertiesCount: 0,
  setLoadedNodePropertiesCount: (loadedNodePropertiesCount) =>
    set((state) => ({
      ...state,
      loadedNodePropertiesCount,
    })),
  loadedSensorPropertiesCount: 0,
  setLoadedSensorPropertiesCount: (loadedSensorPropertiesCount) =>
    set((state) => ({
      ...state,
      loadedSensorPropertiesCount,
    })),
  rawNetworkUpdate: [],

  addRawNetworkUpdate: (update) =>
    set((state) => {
      const newArray = [...state.rawNetworkUpdate, update];
      if (newArray.length > 100) {
        newArray.shift(); // Remove the first element if the array is longer than 10
      }
      return { rawNetworkUpdate: newArray };
    }),
}));

export default useStore
