import React, { useState } from 'react'
import { produce } from 'immer'

// MUI Components
import { IconButton, List, makeStyles } from '@material-ui/core'

// MUI icons
import WifiIcon from '@material-ui/icons/Wifi'
import WifiOffIcon from '@material-ui/icons/WifiOff'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'

// Components
import ComponentHeader from './ComponentHeader'
import GatewayMenu from './GatewayMenu'
import Prop from './Prop'

// Store
import useStore from '../store/store'

// Helpers
import formatSerial from '../helpers/formatSerial'
import isPermissionGranted from '../helpers/isPermissionGranted.js'

// Consts
import Permissions from '../consts/Permissions'

const Gateway = ({ id, name, uuid }) => {
  // Global state
  const networkStateFetched = useStore((state) => state.networkStateFetched)
  const gatewayModels = useStore((state) => state.gatewayModels)
  const networkStateProperties = useStore(
    (state) => state.networkStateProperties
  )
  const gatewayUuid = useStore((state) => state.gatewayUuid)
  const gatewayStatus = useStore((state) => state.gatewayStatus)
  const secondsSinceLastUpdate = useStore(
    (state) => state.secondsSinceLastUpdate
  )
  const loadedGatewayPropertiesCount = useStore(
    (state) => state.loadedGatewayPropertiesCount
  )
  const setLoadedGatewayPropertiesCount = useStore(
    (state) => state.setLoadedGatewayPropertiesCount
  )

  // Local state
  const [editingProperties, setEditingProperties] = useState([])

  // Styles
  const classes = useStyles({
    secondsSinceLastUpdate,
    loadedGatewayPropertiesCount,
  })

  const showProperties = () => {
    const handleEditButtonClick = (prop) => {
      if (!editingProperties.includes(prop.name)) {
        const editing = produce(editingProperties, (draft) => {
          draft.push(prop.name)
        })
        setEditingProperties(editing)
      } else {
        const editing = editingProperties.filter((name) => name !== prop.name)
        setEditingProperties(editing)
      }
    }

    if (gatewayModels && gatewayModels.length > 0) {
      let properties = [...gatewayModels[0].Properties]
      if (networkStateFetched && networkStateProperties) {
        properties = properties.map((property) => {
          const matchingProp = networkStateProperties.find(
            (p) => p.id === 'g.' + gatewayUuid + '.' + property.name
          )
          return matchingProp ? { ...property, ...matchingProp } : property
        })
      } else {
        properties = properties.map((property) => ({
          ...property,
          v: undefined,
        }))
      }

      if (loadedGatewayPropertiesCount === 0) {
        setLoadedGatewayPropertiesCount(
          properties.filter(
            (prop) => prop.hasOwnProperty('v') && prop.v !== undefined
          ).length
        )
      }

      return properties
        .filter(
          (prop) =>
            isPermissionGranted(Permissions.show_hidden_properties) ||
            !(prop.hasOwnProperty('hidden') && prop.hidden === true)
        )
        .map((prop) => (
          <Prop
            prop={prop}
            key={prop.name}
            editingProperties={editingProperties}
            gatewayUuid={uuid}
            handleEditButtonClick={handleEditButtonClick}
          ></Prop>
        ))
    }
  }

  return (
    <List
      className={classes.root}
      subheader={
        <ComponentHeader
          caption={`serial: ${formatSerial(uuid)}`}
          name={name}
          icon={
            networkStateFetched ? (
              <IconButton
                disabled={gatewayStatus === 'offline'}
                style={{ pointerEvents: 'none' }}
                color='primary'
              >
                {gatewayStatus === 'offline' ? <WifiOffIcon /> : <WifiIcon />}
              </IconButton>
            ) : (
              <IconButton
                disabled={true}
                style={{ pointerEvents: 'none' }}
                color='primary'
              >
                <DoDisturbIcon />
              </IconButton>
            )
          }
          menuComponent={<GatewayMenu gatewayId={id} name={name} />}
        />
      }
    >
      {showProperties()}
    </List>
  )
}

export default Gateway

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    // Figma
    display: 'flex',
    width: '360px',
    minWidth: '280px',
    maxWidth: '360px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexShrink: '0',
    borderRadius: '12px',
    background:
      props.loadedGatewayPropertiesCount > 0 &&
      props.secondsSinceLastUpdate > 10
        ? '#FF7F7F'
        : 'white',
    boxShadow: '2px 4px 9px 0px rgba(0, 0, 0, 0.06)',
  }),
  icon: {
    display: 'flex',
    height: '10px',
  },
  monitoring: {
    // Figma
    display: 'flex',
    minHeight: '46px',
    padding: '14px 28px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    flexDirection: 'row',
  },
  value: {
    textAlign: 'right',
  },
  name: {
    display: 'flex',
    textAlign: 'right',
  },
  valueContainer: {
    width: '100',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}))
