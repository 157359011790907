import React from 'react'

// Libraries
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import * as Yup from 'yup'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { Formik, Form, Field } from 'formik'
import { Link, Navigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

// Store
import useStore from '../store/store'

import login from '../actions/login'

const LoginSchema = Yup.object().shape({
  iso2: Yup.string().required('Required'),
  phone: Yup.string()
    .required('Required')
    .matches(/^[\d\s]+X?$/, 'Invalid phone number!'),
  password: Yup.string().required('Required'),
})

export default function LoginFormPage() {
  // State.
  const countries = useStore((state) => state.countries)
  const accessToken = localStorage.getItem('accessToken')
  const formError = useStore((state) => state.formError)
  const iso2 = localStorage.getItem('iso2') || ''
  const loading = useStore((state) => state.loading)
  const password = ''
  const phone = ''
  const setFormError = useStore((state) => state.setFormError)

  // Styles.
  const classes = useStyles()

  // If we have successfully obtained the token, then redirect to main.
  if (accessToken) {
    return <Navigate to='/' />
  }

  return (
    countries &&
    countries.length > 0 && (
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <img
            alt='Glass-Link logo'
            className={classes.logo}
            src={process.env.PUBLIC_URL + '/logo.png'}
          />
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <Formik
            className={classes.form}
            initialValues={{ iso2: iso2, phone: phone, password: password }}
            onSubmit={login}
            validationSchema={LoginSchema}
            validateOnBlur
            validateOnChange
          >
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <Form>
                <InputLabel id='country-label' shrink>
                  Country
                </InputLabel>
                <Field
                  component={Select}
                  name='iso2'
                  value={values.iso2}
                  disabled={loading}
                  error={errors.iso2 && touched.iso2}
                  fullWidth
                  id='iso2'
                  labelId='country-label'
                  onBlur={handleBlur}
                  inputProps={{
                    name: 'iso2',
                    id: 'iso2-native-helper',
                  }}
                  onChange={(value) => {
                    formError && setFormError(null)
                    handleChange(value)
                  }}
                  size='small'
                  margin='dense'>
                  {countries.map((i) => (
                    <MenuItem key={i.Iso2} value={i.Iso2}>
                      {' '}
                      {i.Name}{' '}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  autoComplete='tel-national'
                  autoFocus
                  component={TextField}
                  value={values.phone}
                  disabled={loading}
                  error={errors.phone && touched.phone}
                  fullWidth
                  helperText={errors.phone && touched.phone ? errors.phone : ''}
                  id='phone'
                  label='Phone'
                  margin='normal'
                  name='phone'
                  onBlur={handleBlur}
                  onChange={(value) => {
                    formError && setFormError(null)
                    handleChange(value)
                  }}
                  size='small'
                  type='tel'
                  variant='outlined'
                />
                <Field
                  component={TextField}
                  value={values.password}
                  disabled={loading}
                  error={errors.password && touched.password}
                  fullWidth
                  helperText={
                    errors.password && touched.password ? errors.password : ''
                  }
                  id='password'
                  label='Password'
                  margin='normal'
                  name='password'
                  onBlur={handleBlur}
                  onChange={(value) => {
                    formError && setFormError(null)
                    handleChange(value)
                  }}
                  size='small'
                  type='password'
                  variant='outlined'
                />
                {formError && (
                  <Typography
                    align='center'
                    className={classes.errorMessage}
                    color='error'
                    variant='body1'
                  >
                    Incorrect login details!
                  </Typography>
                )}
                <div className={classes.wrapper}>
                  <Button
                    color='primary'
                    disabled={loading}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    Sign In
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
                <Link to='/register'>
                  <Typography align='center' color='primary' variant='body1'>
                    <b>Don&apos;t have an account?</b> Sign up instead!
                  </Typography>
                </Link>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    )
  )
}

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    color: 'primary',
    left: '50%',
    marginLeft: -12,
    marginTop: -12,
    position: 'absolute',
    top: '50%',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
  },
  form: {
    marginTop: theme.spacing(1),
    width: '100%', // Fix IE 11 issue.
  },
  logo: {
    marginBottom: theme.spacing(8),
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(8),
  },
  wrapper: {
    margin: theme.spacing(3, 0, 2),
    position: 'relative',
  },
}))
