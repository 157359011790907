import React, { useState, useEffect } from 'react'

// MUI components
import {
  Box,
  IconButton,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import Slider from '@mui/material/Slider';

// MUI icons
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'

// Components
import SavingDatePicker from './SavingDateTimePicker'
import SavingSwitch from './SavingSwitch'
import SavingTextField from './SavingTextField'

// Helpers
import formatProp from '../helpers/formatProp'
import isPropNumeric from '../helpers/isPropNumeric'

// Actions
import setGatewayPropertyValue from '../actions/setGatewayPropertyValue'
import setNodePropertyValue from '../actions/setNodePropertyValue'
import setSensorPropertyValue from '../actions/setSensorPropertyValue'

const Prop = ({
  prop,
  editingProperties = [],
  nodeUuid = null,
  index = null,
  gatewayUuid = null,
  handleEditButtonClick
}) => {
  const classes = useStyles()

  const [sliderValue, setSliderValue] = useState(parseFloat(prop.v) || 0);

  useEffect(() => {
    setSliderValue(parseFloat(prop.v) || 0);
  }, [prop.v])
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  }

  const handleSliderChangeCommitted = (event, newValue) => {
    if (nodeUuid && index) {
      setSensorPropertyValue(nodeUuid, index, prop.name, newValue)
    } else if (nodeUuid) {
      setNodePropertyValue(nodeUuid, prop.name, newValue)
    } else if (gatewayUuid) {
      setGatewayPropertyValue(gatewayUuid, prop.name, newValue)
    }
    setSliderValue(newValue)
  }

  return (
    <Box className={classes.monitoring} key={prop.name}>
      <Box className={classes.name}>
        {prop.label ? prop.label : prop.name}
      </Box>
      <Box className={classes.valueContainer}>
        <Box className={classes.value}>
          {!editingProperties.includes(prop.name) && formatProp(prop)}

          {editingProperties.includes(prop.name) && prop.type === 'bool' && (
            <SavingSwitch
              gatewayUuid={gatewayUuid}
              nodeUuid={nodeUuid}
              index={index}
              prop={prop}
            />
          )}

          {editingProperties.includes(prop.name) && (isPropNumeric(prop) || prop.unit === 'ms') && (
            <SavingTextField
              gatewayUuid={gatewayUuid}
              nodeUuid={nodeUuid}
              index={index}
              prop={prop}
            />
          )}

          {editingProperties.includes(prop.name) && prop.unit === 'date' && (
            <SavingDatePicker
              gatewayUuid={gatewayUuid}
              nodeUuid={nodeUuid}
              index={index}
              prop={prop}
            />
          )}
        </Box>
        <Box className={classes.editIcon}>
          {prop.hasOwnProperty('v') && prop.writable && (
            <IconButton onClick={() => handleEditButtonClick(prop)}>
              {editingProperties.includes(prop.name) && (
                <Tooltip title='Close' placement='right'>
                  <CloseIcon
                    color={
                      editingProperties.includes(prop.name)
                        ? 'primary'
                        : 'inherit'
                    }
                  />
                </Tooltip>
              )}
              {!editingProperties.includes(prop.name) && (
                <EditIcon
                  color={
                    editingProperties.includes(prop.name)
                      ? 'primary'
                      : 'inherit'
                  }
                />
              )}
            </IconButton>
          )}
        </Box>
      </Box>
      {prop.writable && prop.unit === 'slider' && (
        <Slider
          aria-label="Value"
          value={sliderValue}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderChangeCommitted}
          valueLabelDisplay="auto"
        />
      )}
    </Box>
  )
}

export default Prop

const useStyles = makeStyles((theme) => ({
  editIcon: {
    display: 'flex',
    height: '10px',
  },
  monitoring: {
    // Figma
    display: 'flex',
    minHeight: '46px',
    padding: '14px 28px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    flexDirection: 'row',
  },
  value: {
    textAlign: 'right',
  },
  name: {
    display: 'flex',
    textAlign: 'right',
  },
  valueContainer: {
    width: '100',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));
