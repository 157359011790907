import React, { useCallback, useEffect, useState } from 'react'

import { Formik, Form, Field } from 'formik'
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  List,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core'
import { FormControlLabel } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'
import cloneDeep from 'lodash/cloneDeep'

// Components
import ComponentHeader from './../components/ComponentHeader'

// Actions
import addLogic from '../actions/addLogic'
import updateLogic from '../actions/updateLogic'

// Store
import useStore from '../store/store'

// Helpers
import getNodeModel from '../helpers/getNodeModel'
import getSensorModel from '../helpers/getSensorModel'

const AddLogicSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
})

const AddLogicForm = () => {
  // Styles.
  const classes = useStyles()

  const logicToUpdate = useStore((state) => state.logicToUpdate)
  const setLogicToUpdate = useStore((state) => state.setLogicToUpdate)

  const loading = useStore((state) => state.loading)
  const formError = useStore((state) => state.formError)
  const setFormError = useStore((state) => state.setFormError)
  const nodes = useStore((state) => state.nodes)
  const gateways = useStore.getState().gateways
  const gatewayModels = useStore((state) => state.gatewayModels)

  const [isFormVisible, setIsFormVisible] = useState(false)
  const [properties, setProperties] = useState([])
  const [activeProperty, setActiveProperty] = useState('')
  const [writableProperties, setWritableProperties] = useState([])
  const [activeWritableProperty, setActiveWritableProperty] = useState('')
  const name = (logicToUpdate && logicToUpdate.Name) || ''
  const description = (logicToUpdate && logicToUpdate.Description) || ''
  const [operator, setOperator] = useState('')
  const [value, setValue] = useState('')
  const [assignedValue, setAssignedValue] = useState('')
  const [elseValue, setElseValue] = useState('')
  const [isElseVisible, setIsElseVisible] = useState(false)

  const getProperties = useCallback(
    (component, writable = false) => {
      var props = []
      if (component && component.ComponentType === 'node') {
        const model = getNodeModel(component.NodeModelId)
        if (model) {
          if (writable) {
            props = model.Properties.filter((prop) => prop.writable)
          } else {
            props = model.Properties
          }
          props = props.map((prop) => {
            const propCopy = cloneDeep(prop)
            propCopy.logicLabel = component.Name + ' - ' + prop.name
            propCopy.logicName = 'n.' + component.Uuid + '.' + prop.name
            return propCopy
          })
          return props
        }
      } else if (component && component.ComponentType === 'sensor') {
        const model = getSensorModel(component.SensorModelId)
        if (model) {
          if (writable) {
            props = model.Properties.filter((prop) => prop.writable)
          } else {
            props = model.Properties
          }
          props = props.map((prop) => {
            const propCopy = cloneDeep(prop)
            propCopy.logicLabel = component.Name + ' - ' + prop.name
            propCopy.logicName =
              's.' + component.Uuid + '.' + component.Index + '.' + prop.name
            return propCopy
          })
          return props
        }
      } else if (component) {
        const model = gatewayModels[0]
        if (model) {
          if (writable) {
            props = model.Properties.filter((prop) => prop.writable)
          } else {
            props = model.Properties
          }
          props = props.map((prop) => {
            const propCopy = cloneDeep(prop)
            propCopy.logicLabel = component.Name + ' - ' + prop.name
            propCopy.logicName = 'g.' + component.Uuid + '.' + prop.name
            return propCopy
          })
          return props
        }
      }
    },
    [gatewayModels]
  )

  useEffect(() => {
    var propertyList = []
    var writablePropertyList = []
    for (var node of nodes) {
      const newNode = cloneDeep(node)
      newNode.ComponentType = 'node'
      propertyList.push(...getProperties(newNode))
      writablePropertyList.push(...getProperties(newNode, true))
      for (var sensor of node.sensors) {
        const newSensor = Object.assign(
          {
            ComponentType: 'sensor',
            Uuid: node.Uuid,
          },
          sensor
        )
        propertyList.push(...getProperties(newSensor))
        writablePropertyList.push(...getProperties(newSensor, true))
      }
    }
    for (let gateway of gateways) {
      propertyList.push(...getProperties(gateway))
      writablePropertyList.push(...getProperties(gateway, true))
    }
    setProperties(propertyList)
    setWritableProperties(writablePropertyList)

    if (logicToUpdate) {
      setIsFormVisible(true)
      setActiveProperty(logicToUpdate.JsonLogic.if.property)
      setActiveWritableProperty(logicToUpdate.JsonLogic.then.property)
      setOperator(logicToUpdate.JsonLogic.if.operator)
      setValue(logicToUpdate.JsonLogic.if.value)
      setAssignedValue(logicToUpdate.JsonLogic.then.value)
      setElseValue(
        (logicToUpdate.JsonLogic.else && logicToUpdate.JsonLogic.else.value) ||
          ''
      )
      setIsElseVisible(
        (logicToUpdate.JsonLogic.else &&
          logicToUpdate.JsonLogic.else.visible) ||
          false
      )
    } else {
      setActiveProperty('')
      setActiveWritableProperty('')
      setOperator('')
      setValue('')
      setAssignedValue('')
      setElseValue('')
      setIsElseVisible(false)
    }
  }, [nodes, gateways, logicToUpdate, getProperties])

  const onSubmit = async (values, { resetForm }) => {
    let elseVal = activeWritableProperty
    if (isElseVisible && !isNaN(parseFloat(elseValue))) {
      elseVal = parseFloat(elseValue)
    }
    var logic = [
      {
        property: activeWritableProperty,
        logic: {
          if: [
            { [operator]: [activeProperty, parseFloat(value)] },
            parseFloat(assignedValue),
            elseVal,
          ],
        },
      },
    ]
    logic = JSON.stringify(logic)

    var guiValues = {
      if: {
        property: activeProperty,
        operator: operator,
        value: value,
      },
      then: {
        property: activeWritableProperty,
        value: assignedValue,
      },
      else: {
        value: elseValue,
        visible: isElseVisible,
      },
    }
    guiValues = JSON.stringify(guiValues)

    if (logicToUpdate) {
      updateLogic({
        userNetworkLogicId: logicToUpdate.UserNetworkLogicId,
        ...values,
        jsonLogic: guiValues,
        logic: logic,
      })
    } else {
      addLogic({
        ...values,
        jsonLogic: guiValues,
        logic: logic,
      })
    }
    resetForm({})
    setActiveProperty('')
    setActiveWritableProperty('')
    setOperator('')
    setValue('')
    setAssignedValue('')
    setElseValue('')
    setLogicToUpdate(null)
    setIsFormVisible(false)
    setIsElseVisible(false)
  }

  if (isFormVisible) {
    return (
      <Paper className={classes.paper} square>
        <Formik
          className={classes.form}
          initialValues={{
            name: name,
            description: description,
          }}
          onSubmit={onSubmit}
          validationSchema={AddLogicSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            touched,
            values,
          }) => (
            <Form>
              <div>
                <Field
                  autoFocus
                  className={classes.field}
                  component={TextField}
                  error={errors.name && touched.name}
                  helperText={errors.name && touched.name ? errors.name : ''}
                  id='name'
                  label='Name'
                  margin='normal'
                  name='name'
                  onBlur={(e) => {
                    const val = (e.target.value || '').replace(/\s+/gi, ' ')
                    setFieldValue('name', val.trim())
                    handleBlur(e)
                  }}
                  onChange={(value) => {
                    formError && setFormError(null)
                    handleChange(value)
                  }}
                  size='small'
                  type='text'
                  value={values.name}
                  variant='outlined'
                />

                <Field
                  className={classes.field}
                  component={TextField}
                  error={errors.description && touched.description}
                  helperText={
                    errors.description && touched.description
                      ? errors.description
                      : ''
                  }
                  id='description'
                  label='Description'
                  margin='normal'
                  name='description'
                  onBlur={(e) => {
                    const val = (e.target.value || '').replace(/\s+/gi, ' ')
                    setFieldValue('description', val.trim())
                    handleBlur(e)
                  }}
                  onChange={(value) => {
                    formError && setFormError(null)
                    handleChange(value)
                  }}
                  size='small'
                  type='text'
                  value={values.description}
                  variant='outlined'
                />

                <Button
                  className={classes.formButton}
                  color='primary'
                  disabled={loading}
                  type='submit'
                  variant='contained'
                >
                  {logicToUpdate ? 'Save' : 'Add'}
                </Button>
                <Button
                  className={classes.formButton}
                  color='inherit'
                  disabled={loading}
                  onClick={() => {
                    setIsFormVisible(false)
                    setLogicToUpdate(null)
                    setFormError(null)
                    setActiveProperty('')
                    setActiveWritableProperty('')
                    setOperator('')
                    setValue('')
                    setAssignedValue('')
                    setElseValue('')
                    setLogicToUpdate(null)
                    setIsFormVisible(false)
                    setIsElseVisible(false)
                  }}
                  variant='contained'
                >
                  Cancel
                </Button>
              </div>

              <Divider className={classes.divider} />

              <div>
                <Paper square className={classes.block}>
                  <List subheader={<ComponentHeader name='IF' />}>
                    <div>
                      <FormControl
                        variant='outlined'
                        className={classes.formControl}
                      >
                        <InputLabel id='property-label'>Property</InputLabel>
                        <Select
                          labelId='property-label'
                          id='property'
                          label='Property'
                          margin='dense'
                          value={activeProperty}
                          onChange={(event) => {
                            formError && setFormError(null)
                            setActiveProperty(event.target.value)
                          }}
                          required
                        >
                          {properties.map((property) => (
                            <MenuItem
                              value={property.logicName}
                              key={property.logicName}
                            >
                              {property.logicLabel}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl
                        variant='outlined'
                        className={classes.formControl}
                      >
                        <InputLabel id='operator-label'>Operator</InputLabel>
                        <Select
                          labelId='operator-label'
                          id='operator'
                          margin='dense'
                          value={operator}
                          onChange={(event) => {
                            setOperator(event.target.value)
                          }}
                          required
                        >
                          <MenuItem value='=='>{'=='}</MenuItem>
                          <MenuItem value='!='>{'!='}</MenuItem>
                          <MenuItem value='>'>{'>'}</MenuItem>
                          <MenuItem value='>='>{'>='}</MenuItem>
                          <MenuItem value='<'>{'<'}</MenuItem>
                          <MenuItem value='<='>{'<='}</MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        id='value'
                        label='Value'
                        margin='dense'
                        onChange={(event) => {
                          setValue(event.target.value)
                        }}
                        step='0.1'
                        type='number'
                        value={value}
                        variant='outlined'
                        required
                      />
                    </div>
                  </List>
                </Paper>
              </div>
              <div>
                <Paper square className={classes.block}>
                  <List subheader={<ComponentHeader name='THEN' />}>
                    <div>
                      <FormControl
                        variant='outlined'
                        className={classes.formControl}
                      >
                        <InputLabel id='property-label'>Property</InputLabel>
                        <Select
                          labelId='property-label'
                          id='property'
                          margin='dense'
                          value={activeWritableProperty}
                          onChange={(event) => {
                            formError && setFormError(null)
                            setActiveWritableProperty(event.target.value)
                          }}
                          label='Property'
                          required
                        >
                          {writableProperties.map((property) => (
                            <MenuItem
                              value={property.logicName}
                              key={property.logicName}
                            >
                              {property.logicLabel}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Button>=</Button>
                      <TextField
                        id='assigned-value'
                        label='Value'
                        margin='dense'
                        onChange={(event) => {
                          setAssignedValue(event.target.value)
                        }}
                        step='0.1'
                        type='number'
                        value={assignedValue}
                        variant='outlined'
                        required
                      />
                    </div>
                  </List>
                </Paper>
              </div>
              <FormControlLabel
                label='Else'
                control={
                  <Checkbox
                    checked={isElseVisible}
                    onChange={() => setIsElseVisible(!isElseVisible)}
                  />
                }
              />
              {isElseVisible && (
                <div>
                  <Paper square className={classes.block}>
                    <List subheader={<ComponentHeader name='ELSE' />}>
                      <div>
                        <TextField
                          id='else-value'
                          label='Value'
                          margin='dense'
                          onChange={(event) => {
                            setElseValue(event.target.value)
                          }}
                          step='0.1'
                          type='number'
                          value={elseValue}
                          variant='outlined'
                        />
                      </div>
                    </List>
                  </Paper>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Paper>
    )
  } else {
    return (
      <Button
        className={classes.addButton}
        color='primary'
        disabled={loading}
        onClick={() => setIsFormVisible(!isFormVisible)}
        variant='contained'
      >
        Add Logic
      </Button>
    )
  }
}

const useStyles = makeStyles((theme) => ({
  addButton: {
    float: 'right',
    margin: theme.spacing(2, 1, 2),
  },
  block: {
    display: 'inline-block',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  formButton: {
    margin: theme.spacing(2, 1, 2),
    position: 'relative',
  },
  errorMessage: {
    float: 'center',
    marginBottom: theme.spacing(2),
  },
  field: {
    marginRight: theme.spacing(1),
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    width: '100%', // Fix IE 11 issue.
  },
  formControl: {
    minWidth: 200,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    marginTop: 15,
  },
  insertButton: {
    margin: theme.spacing(1, 1, 0),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 1, 2),
  },
}))

export default AddLogicForm
