import React, { useState } from 'react'
import clsx from 'clsx'
import { Route, Routes } from 'react-router-dom'

import {
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

// Icons
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// Components
import AddGatewayForm from '../forms/AddGatewayForm';
import AddLogicForm from '../forms/AddLogicForm';
import AddNodeForm from '../forms/AddNodeForm';
import Gateway from './GatewayPage';
import Logic from './LogicPage';
import MainMenuItems from '../components/MainMenuItems';
import Nodes from './NodesPage';
import Lamps from './LampsPage';
import Page from '../components/Page';
import Sensors from './SensorsPage';
import DashboardsPage from './DashboardPage';
import RawPage from './RawPage';

// Store
import useStore from '../store/store';

// Actions
import logout from '../actions/logout';
import getAPIKey from '../actions/getAPIKey';
import revokeAPIKey from '../actions/revokeAPIKey';

// Helpers
import getPhoneCode from '../helpers/getPhoneCode';
import isPermissionGranted from '../helpers/isPermissionGranted.js';

// Consts
import ErrorCodes from '../consts/ErrorCodes';
import Permissions from '../consts/Permissions';

// Git info
import generatedGitInfo from '../generatedGitInfo.json';

export default function MainPage() {
  const title = useStore((state) => state.title);
  const isAddGatewayFormVisible = useStore(
    (state) => state.isAddGatewayFormVisible
  );
  const isAddLogicFormVisible = useStore(
    (state) => state.isAddLogicFormVisible
  );
  const isAddNodeFormVisible = useStore((state) => state.isAddNodeFormVisible);
  const formError = useStore((state) => state.formError);
  const successMessage = useStore((state) => state.successMessage);
  const siteName = 'Glass-Link';
  const [snackbarOpen, setSnackbarOpen] = useState(true);
  const iso2 = localStorage.getItem('iso2');
  const phone = localStorage.getItem('phone');
  const APIKey = useStore((state) => state.APIKey);
  const [revokeConfirmation, setRevokeConfirmation] = useState('');

  const classes = useStyles();
  // close the drawer on narrow screens
  const [drawerOpen, setDrawerOpen] = React.useState(
    window.screen.width >= 1280 ? true : false
  );

  const [anchor, setAnchor] = useState();
  const [getAPIKeyDialogOpen, setGetAPIKeyDialogOpen] = useState(false);
  const [revokeAPIKeyDialogOpen, setRevokeAPIKeyDialogOpen] = useState(false);
  const [aboutDialogOpen, setAboutDialogOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleGetAPIKeyClick = () => {
    getAPIKey();
    setGetAPIKeyDialogOpen(true);
    setAnchor(null);
  };

  const handleGetAPIDialogCancel = () => {
    setGetAPIKeyDialogOpen(false);
  };

  const handleRevokeAPIKeyClick = () => {
    getAPIKey();
    setRevokeAPIKeyDialogOpen(true);
    setAnchor(null);
  };

  const handleRevokeAPIKeyDialogCancel = () => {
    setRevokeAPIKeyDialogOpen(false);
    setRevokeConfirmation('');
  };

  const handleAboutClick = () => {
    setAboutDialogOpen(true);
    setAnchor(null);
  };

  const handleAboutDialogCancel = () => {
    setAboutDialogOpen(false);
  };

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const setErrorMessage = (errCode) => {
    switch (errCode) {
      case ErrorCodes.access_denied:
        return 'Access denied!';
      case ErrorCodes.already_assigned:
        return 'Already assigned!';
      case ErrorCodes.already_confirmed:
        return 'Already confirmed!';
      case ErrorCodes.auth_failed:
        return 'Authorization failed!';
      case ErrorCodes.gateway_not_connected:
        return 'Gateway not connected!';
      case ErrorCodes.gateway_not_found:
        return 'Gateway not found!';
      case ErrorCodes.internal_server_error:
        return 'Internal server error!';
      case ErrorCodes.invalid_param:
        return 'Invalid parameter!';
      case ErrorCodes.invalid_token:
        return 'Invalid token!';
      case ErrorCodes.node_not_found:
        return 'Node not found!';
      case ErrorCodes.not_compatible:
        return 'Attachment not compatible!';
      case ErrorCodes.phone_exists:
        return 'Phone already exists!';
      case ErrorCodes.phone_not_found:
        return 'Phone not found!';
      case ErrorCodes.too_many_attempts:
        return 'Too many attempts!';
      case ErrorCodes.rpc_communication_error:
        return 'Error during communication with the server via WebSocket!';
      case ErrorCodes.other:
        return 'Something went wrong (error code 15)!';
      case ErrorCodes.failed_to_create_websocket:
        return 'Failed to create WebSocket instance!';
      case ErrorCodes.err_network:
        return 'Network error (error code 17)!';
      default:
        return 'Something went wrong!';
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='absolute'
        className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              drawerOpen && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component='h1'
            variant='h6'
            color='inherit'
            noWrap
            className={classes.title}
          >
            {title}
          </Typography>
          <IconButton
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            onClick={handleClick}
            className={classes.accountIcon}
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id='menu'
            anchorEl={anchor}
            keepMounted
            open={Boolean(anchor)}
            onClose={handleClose}
          >
            <MenuItem>
              Signed in as&nbsp;
              <b>
                +{getPhoneCode(iso2)} {phone}
              </b>
            </MenuItem>
            <Divider />
            {isPermissionGranted(Permissions.api_key) && (
              <Box>
                <MenuItem onClick={handleGetAPIKeyClick}>Get API Key</MenuItem>
                <MenuItem onClick={handleRevokeAPIKeyClick}>
                  Revoke API Key
                </MenuItem>
                <Divider />
              </Box>
            )}
            <MenuItem onClick={logout}>Sign out</MenuItem>
            <Divider />
            <MenuItem onClick={handleAboutClick}>About</MenuItem>
          </Menu>

          {/* GET API KEY */}
          <Dialog
            open={getAPIKeyDialogOpen}
            onClose={handleGetAPIDialogCancel}
            aria-labelledby='form-dialog-title'
          >
            <DialogTitle id='form-dialog-title'>Your API Key</DialogTitle>
            <DialogContent>
              <TextField
                disabled
                fullWidth
                id='api-key'
                multiline
                value={APIKey}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => navigator.clipboard.writeText(APIKey)}
                color='primary'
              >
                Copy
              </Button>
              <Button onClick={handleGetAPIDialogCancel} color='primary'>
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* REVOKE API KEY */}
          <Dialog
            open={revokeAPIKeyDialogOpen}
            onClose={handleRevokeAPIKeyDialogCancel}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>Revoke API Key</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                You are going to revoke your API key. This action is
                irreversible, but you can request a new API key.
              </DialogContentText>
              <TextField
                autoFocus
                margin='dense'
                id='revoke-confirmation'
                label='Type REVOKE to confirm'
                value={revokeConfirmation}
                onChange={(event) => {
                  setRevokeConfirmation(event.target.value);
                }}
                fullWidth
                error={revokeConfirmation.toLowerCase().trim() !== 'revoke'}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRevokeAPIKeyDialogCancel} color='primary'>
                No
              </Button>
              <Button
                onClick={() => {
                  if (revokeConfirmation.toLowerCase().trim() === 'revoke') {
                    revokeAPIKey();
                    setRevokeAPIKeyDialogOpen(false);
                    setRevokeConfirmation('');
                  }
                }}
                color='primary'
                autoFocus
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          {/* ABOUT */}
          <Dialog
            open={aboutDialogOpen}
            onClose={handleAboutDialogCancel}
            aria-labelledby='form-dialog-title'
          >
            <DialogTitle id='form-dialog-title'>About</DialogTitle>
            <DialogContent>Glass-Link</DialogContent>
            <DialogContent>
              Version: {generatedGitInfo.gitCommitHash}
            </DialogContent>
            <DialogContent>
              Date: {new Date(generatedGitInfo.gitCommitDate).toLocaleString()}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleAboutDialogCancel} color='primary'>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(
            classes.drawerPaper,
            !drawerOpen && classes.drawerPaperClose
          ),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbarIcon}>
          <img
            alt='Glass-Link logo'
            className={classes.logo}
            src={process.env.PUBLIC_URL + '/logo.png'}
          />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <MainMenuItems activePage={title} />
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        {isAddGatewayFormVisible &&
          isPermissionGranted(Permissions.add_gateway) && <AddGatewayForm />}

        {isAddNodeFormVisible && isPermissionGranted(Permissions.add_node) && (
          <AddNodeForm />
        )}

        {isAddLogicFormVisible &&
          isPermissionGranted(Permissions.logic_screen) && <AddLogicForm />}

        <Container maxWidth={false} className={classes.container}>
          <Grid container spacing={3}>
            <Routes>
              {isPermissionGranted(Permissions.gateway_screen) && (
                <Route
                  exact
                  path='*'
                  element={
                    <Page title={`${siteName} - Gateway`}>
                      <Gateway className={classes.gateway} />
                    </Page>
                  }
                />
              )}
              {isPermissionGranted(Permissions.logic_screen) && (
                <Route
                  path='/logic'
                  element={
                    <Page title={`${siteName} - Logic`}>
                      <Logic />
                    </Page>
                  }
                />
              )}
              {isPermissionGranted(Permissions.nodes_screen) && (
                <Route
                  path='/nodes'
                  element={
                    <Page title={`${siteName} - Nodes`}>
                      <Nodes className={classes.nodes} />
                    </Page>
                  }
                />
              )}
              {isPermissionGranted(Permissions.attachments_screen) && (
                <Route
                  path='/sensors'
                  element={
                    <Page title={`${siteName} - Attachments`}>
                      <Sensors className={classes.sensors} />
                    </Page>
                  }
                />
              )}
              {isPermissionGranted(Permissions.dashboard) && (
                <Route
                  path='/dashboard'
                  element={
                    <Page title={`${siteName} - Dashboard`}>
                      <DashboardsPage />
                    </Page>
                  }
                />
              )}
              {isPermissionGranted(Permissions.raw) && (
                <Route
                  path='/raw'
                  element={
                    <Page title={`${siteName} - Raw`}>
                      <RawPage />
                    </Page>
                  }
                />
              )}
              {isPermissionGranted(Permissions.lamps_screen) && (
                <Route
                  path='/lamps'
                  element={
                    <Page title={`${siteName} - Lamps`}>
                      <Lamps className={classes.nodes} />
                    </Page>
                  }
                />
              )}
            </Routes>
          </Grid>
        </Container>

        {formError && (
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity='error'>
              {setErrorMessage(formError)}
            </Alert>
          </Snackbar>
        )}

        {successMessage && (
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity='success'>
              {successMessage}
            </Alert>
          </Snackbar>
        )}
      </main>
    </div>
  );
}

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    background: '#F8FBFF',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  logo: {
    width: '75%',
    height: undefined,
    aspectRatio: 4.8125,
  },
  nodes: {
    // Figma
    display: 'flex',
    width: '1200px',
    height: '960px',
    padding: '40px',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '32px',
    flexShrink: '0',
  },
  gateway: {
    // Figma
    display: 'flex',
    width: '1200px',
    height: '960px',
    padding: '60px 40px 0px 40px',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: '20px',
    flexShrink: '0',
    flexWrap: 'wrap',
  },
  sensors: {
    // Figma
    width: '1200px',
    height: '960px',
    flexShrink: '0',
  },
  accountIcon: {
    color: '#F8FBFF',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: 'transparent',
    },
  },
}))
