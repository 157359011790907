import React, { useState, useEffect, useMemo } from 'react';
import useStore from '../store/store';
import * as dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core/styles'

// UI components
import { Box, IconButton, Select, MenuItem } from '@material-ui/core';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { DatePicker } from 'antd';
import TimeRange from '@harveyconnor/react-timeline-range-slider';

// Actions
import updateDataPanel from '../actions/updateDataPanel';

// Helpers
import useIsMobile from '../helpers/useIsMobile';

const DashboardsPage = ({ uniqueId, accessToken }) => {
  const dataPanels = useStore((state) => state.dataPanels);
  const setTitle = useStore((state) => state.setTitle);
  const { RangePicker } = DatePicker;
  const [error, setError] = useState(false);
  const [sliderConfigs, setSliderConfigs] = useState([]);
  const isMobile = useIsMobile();
  const [fullScreenPanels, setFullScreenPanels] = useState([]);

  // Styles.
  const classes = useStyles({
    isMobile
  })

  useEffect(() => {
    if (dataPanels) {
      const newSliderConfigs = [];

      for (let i = 0; i < dataPanels.length; i++) {
        const dataPanel = dataPanels[i];
        if (dataPanel.Configuration && dataPanel.Configuration.slider) {
          const getTimelineInterval = (dataPanel) => {
            if (dataPanel.Configuration) {
              if (dataPanel.Configuration.from && dataPanel.Configuration.to) {
                return [
                  dayjs(dataPanel.Configuration.from),
                  dayjs(dataPanel.Configuration.to),
                ];
              } else if (dataPanel.Configuration.timeRange) {
                return timeRangeToSliderInterval(
                  dataPanel.Configuration.timeRange
                );
              }
            }
            return [dayjs().startOf('day'), dayjs().endOf('day')];
          };

          const interval = getTimelineInterval(dataPanel);
          if (interval === null) continue;

          const newTimeline = {
            id: dataPanel.DataPanelId,
            interval,
            selectedInterval: interval,
            maxIntervalSelected: true,
          };

          newSliderConfigs.push(newTimeline);
        }
      }

      setSliderConfigs(newSliderConfigs);
    }
  }, [dataPanels]);

  const onSliderError = ({ error }) => {
    setError(error);
  };

  const compareIntervals = (a, b) => {
    if (!a || !b) return false;
    if (a.length !== b.length) return false;
    return dayjs(a[0]).isSame(dayjs(b[0])) && dayjs(a[1]).isSame(dayjs(b[1]));
  };
  const handleSliderChange = (id, selectedInterval) => {
    if (!selectedInterval || selectedInterval.length < 2) return;

    const interval = sliderConfigs.find((t) => t.id === id)?.interval;
    let maxIntervalSelected = compareIntervals(interval, selectedInterval);

    setSliderConfigs((prevSliderConfigs) =>
      prevSliderConfigs.map((sliderConfig) =>
        sliderConfig.id === id
          ? { ...sliderConfig, selectedInterval, maxIntervalSelected }
          : sliderConfig
      )
    );
  };

  useEffect(() => {
    setTitle('Dashboard');
  }, [setTitle]);

  const globalStyle = {
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    width: '100vw',
  };

  document.body.style.margin = globalStyle.margin;
  document.body.style.padding = globalStyle.padding;
  document.body.style.overflow = globalStyle.overflow;
  document.documentElement.style.height = globalStyle.height;
  document.documentElement.style.width = globalStyle.width;

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '50vh', // or any other height you want
  };

  const containerFullScreenStyle = {
    display: 'flex',
    flexDirection: 'column',
    height: '85vh', // or any other height you want
  };

  const iframeContainerStyle = {
    flex: 1,
    overflow: 'hidden',
    width: '100%',
  };

  const iframeStyle = {
    width: '100%',
    height: '100%',
    border: 'none',
  };

  const timeRanges = [
    { label: 'Custom', value: 'custom', key: 'custom-key' },
    { label: 'Last 5 minutes', value: '5m', key: '5m-key' },
    { label: 'Last 15 minutes', value: '15m', key: '15m-key' },
    { label: 'Last 30 minutes', value: '30m', key: '30m-key' },
    { label: 'Last 1 hour', value: '1h', key: '1h-key' },
    { label: 'Last 3 hours', value: '3h', key: '3h-key' },
    { label: 'Last 6 hours', value: '6h', key: '6h-key' },
    { label: 'Last 12 hours', value: '12h', key: '12h-key' },
    { label: 'Last 24 hours', value: '24h', key: '24h-key' },
    { label: 'Last 2 days', value: '2d', key: '2d-key' },
    { label: 'Last 7 days', value: '7d', key: '7d-key' },
    { label: 'Last 30 days', value: '30d', key: '30d-key' },
    { label: 'Today', value: 'today', key: 'today-key' },
    { label: 'Yesterday', value: 'yesterday', key: 'yesterday-key' },
    { label: '2 days ago', value: '2d-ago', key: '2d-ago-key' },
  ];

  const timeRangeToSliderInterval = (timeRange) => {
    const dates = [];
    switch (timeRange) {
      case '5m':
      case '15m':
      case '30m':
      case '1h':
        return null;
      case '3h':
        dates.push(dayjs().subtract(3, 'hour'), dayjs());
        break;
      case '6h':
        dates.push(dayjs().subtract(6, 'hour'), dayjs());
        break;
      case '12h':
        dates.push(dayjs().subtract(12, 'hour'), dayjs());
        break;
      case '24h':
        dates.push(dayjs().subtract(24, 'hour'), dayjs());
        break;
      case '2d':
        dates.push(dayjs().subtract(2, 'day'), dayjs());
        break;
      case '7d':
        dates.push(dayjs().subtract(7, 'day'), dayjs());
        break;
      case '30d':
        dates.push(dayjs().subtract(30, 'day'), dayjs());
        break;
      case 'today':
        dates.push(dayjs().startOf('day'), dayjs());
        break;
      case 'yesterday':
        dates.push(
          dayjs().subtract(1, 'day').startOf('day'),
          dayjs().subtract(1, 'day').endOf('day')
        );
        break;
      case '2d-ago':
        dates.push(
          dayjs().subtract(2, 'day').startOf('day'),
          dayjs().subtract(2, 'day').endOf('day')
        );
        break;
      default:
        dates.push(dayjs().startOf('day'), dayjs().endOf('day'));
        break;
    }
    return dates;
  };

  const handleSelectChange = (dataPanelId, timeRange) => {
    const newDataPanelConfiguration = {
      ...dataPanels.find((dp) => dp.DataPanelId === dataPanelId).Configuration,
      timeRange,
      from: null,
      to: null,
    };

    updateDataPanel({
      dataPanelId,
      configuration: newDataPanelConfiguration,
    });

    const dates = timeRangeToSliderInterval(timeRange);
    const interval = sliderConfigs.find((t) => t.id === dataPanelId)?.interval;

    let maxIntervalSelected = compareIntervals(interval, dates);

    if (dates && dates.length === 2) {
      setSliderConfigs((prevSliderConfigs) =>
        prevSliderConfigs.map((sliderConfig) =>
          sliderConfig.id === dataPanelId
            ? {
              ...sliderConfig,
              interval: dates,
              selectedInterval: dates,
              maxIntervalSelected,
            }
            : sliderConfig
        )
      );
    } else {
      setSliderConfigs((prevSliderConfigs) =>
        prevSliderConfigs.filter(
          (sliderConfig) => sliderConfig.id !== dataPanelId
        )
      );
    }
  };

  const handlePickerChange = (dataPanelId, dates, datesString) => {
    if (!dates || dates.length < 2) return;

    const newDataPanelConfiguration = {
      ...dataPanels.find((dp) => dp.DataPanelId === dataPanelId).Configuration,
      timeRange: null,
      from: dates[0].valueOf(),
      to: dates[1].valueOf(),
    };

    updateDataPanel({
      dataPanelId,
      configuration: newDataPanelConfiguration,
    });

    const interval = sliderConfigs.find((t) => t.id === dataPanelId)?.interval;
    let maxIntervalSelected = compareIntervals(interval, dates);

    setSliderConfigs((prevSliderConfigs) =>
      prevSliderConfigs.map((sliderConfig) =>
        sliderConfig.id === dataPanelId
          ? {
            ...sliderConfig,
            interval: dates,
            selectedInterval: dates,
            maxIntervalSelected,
          }
          : sliderConfig
      )
    );
  };

  const buildUrl = (dataPanel) => {
    let url = dataPanel.Url;
    if (dataPanel.Configuration) {
      if (dataPanel.Configuration.slider) {
        const sliderConfig = sliderConfigs.find(
          (t) => t.id === dataPanel.DataPanelId
        );

        if (
          sliderConfig &&
          Array.isArray(sliderConfig.selectedInterval) &&
          sliderConfig.selectedInterval.length === 2 &&
          Array.isArray(sliderConfig.interval) &&
          sliderConfig.interval.length === 2 &&
          // use interval selected on the slider only if it is different from the current maximum interval
          !sliderConfig.maxIntervalSelected
        ) {
          const [from, to] = sliderConfig.selectedInterval;

          url +=
            '&from=' + dayjs(from).valueOf() + '&to=' + dayjs(to).valueOf();
          return url;
        }
      }

      if (dataPanel.Configuration.from && dataPanel.Configuration.to) {
        url +=
          '&from=' +
          dayjs(dataPanel.Configuration.from).valueOf() +
          '&to=' +
          dayjs(dataPanel.Configuration.to).valueOf();
      } else if (dataPanel.Configuration.timeRange) {
        if (dataPanel.Configuration.timeRange === 'today') {
          url += '&from=now/d&to=now'; // today so far
        } else if (dataPanel.Configuration.timeRange === 'yesterday') {
          url += '&from=now-1d/d&to=now-1d/d'; // yesterday
        } else if (dataPanel.Configuration.timeRange === '2d-ago') {
          url += '&from=now-2d/d&to=now-2d/d'; // the day before yesterday
        } else
          url += '&from=now-' + dataPanel.Configuration.timeRange + '&to=now';
      } else {
        // default to last 12 h
        url += '&from=now-12h&to=now';
      }
      url += '&refresh=1m';
      return url;
    } else {
      // default to last 12 h
      url += '&from=now-12h&to=now&refresh=1m';
      return url;
    }
  };

  const showSelectValue = (dataPanel) => {
    if (dataPanel.Configuration && dataPanel.Configuration.timeRange)
      return dataPanel.Configuration.timeRange;
    else if (
      dataPanel.Configuration &&
      dataPanel.Configuration.from &&
      dataPanel.Configuration.to
    )
      return 'custom';
    else return '12h';
  };

  const showPickerValue = (dataPanel) => {
    if (
      dataPanel.Configuration &&
      dataPanel.Configuration.from &&
      dataPanel.Configuration.to
    )
      return [
        dayjs(dataPanel.Configuration.from),
        dayjs(dataPanel.Configuration.to),
      ];
    else return null;
  };

  const pickerValues = useMemo(() => {
    if (!dataPanels) return {};
    const values = {};
    dataPanels.forEach((dataPanel) => {
      values[dataPanel.DataPanelId] = showPickerValue(dataPanel);
    });
    return values;
  }, [dataPanels]);

  const formatTick = (ms, dataPanelId) => {
    const interval = sliderConfigs.find((t) => t.id === dataPanelId)?.interval;
    const start = dayjs(interval[0]);
    const end = dayjs(interval[1]);
    if (end.diff(start, 'day') <= 2) return dayjs(new Date(ms)).format('HH:mm');
    return dayjs(new Date(ms)).format('D/M HH:mm');
  };

  const handleFullScreenToggle = (id) => {
    if (fullScreenPanels.includes(id)) {
      setFullScreenPanels(fullScreenPanels.filter((p) => p !== id));
    } else {
      setFullScreenPanels([...fullScreenPanels, id]);
    }
  };

  const showFullScreenButton = (dataPanelId) => {
    return (
      <IconButton
        variant='outlined'
        color='primary'
        onClick={() => handleFullScreenToggle(dataPanelId)}
      >
        {fullScreenPanels.includes(dataPanelId) ? (
          <FullscreenExitIcon />
        ) : (
          <FullscreenIcon />
        )}
      </IconButton>
    );
  };

  return (
    dataPanels && (
      <>
        <div
          style={{
            width: '100%',
            overflow: 'auto',
            marginBottom: '50vh',
          }}
        >
          {dataPanels.map((dataPanel) => (
            <div
              key={dataPanel.DataPanelId}
              style={{
                marginBottom: '25px',
                //display: fullScreenPanel && dataPanel.DataPanelId !== fullScreenPanel ? 'none' : 'block',
              }}
            >
              <span>Time range: </span>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={showSelectValue(dataPanel)}
                label='Time range'
                onChange={(event) =>
                  handleSelectChange(dataPanel.DataPanelId, event.target.value)
                }
              >
                {timeRanges.map((timeRange) => (
                  <MenuItem
                    value={timeRange.value}
                    key={timeRange.value}
                    disabled={timeRange.value === 'custom'}
                  >
                    {timeRange.label}
                  </MenuItem>
                ))}
              </Select>
              {isMobile && showFullScreenButton(dataPanel.DataPanelId)}
              <RangePicker
                allowClear={false}
                showTime
                onChange={(dates, datesString) =>
                  handlePickerChange(dataPanel.DataPanelId, dates, datesString)
                }
                value={pickerValues[dataPanel.DataPanelId]}
              />
              {!isMobile && showFullScreenButton(dataPanel.DataPanelId)}
              {sliderConfigs &&
                sliderConfigs.find((t) => t.id === dataPanel.DataPanelId) && (
                <Box>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    marginTop='10px'
                    marginBottom='10px'
                  >
                    <span>Selected Interval: </span>
                    {sliderConfigs
                      .find((t) => t.id === dataPanel.DataPanelId)
                      .selectedInterval.map((date, index) => (
                        <span key={index}>
                              &nbsp;{dayjs(date).format('D/M HH:mm')}
                          {index === 1 ? '' : ' - '}
                        </span>
                      ))}
                  </Box>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    marginTop='20px'
                    marginBottom='20px'
                  >
                    <TimeRange
                      error={error}
                      formatTick={(ms) =>
                        formatTick(ms, dataPanel.DataPanelId)
                      }
                      ticksNumber={isMobile ? 12 : 24}
                      selectedInterval={
                        sliderConfigs.find(
                          (t) => t.id === dataPanel.DataPanelId
                        ).selectedInterval
                      }
                      timelineInterval={
                        sliderConfigs.find(
                          (t) => t.id === dataPanel.DataPanelId
                        ).interval
                      }
                      onUpdateCallback={onSliderError}
                      onChangeCallback={(selectedInterval) =>
                        handleSliderChange(
                          dataPanel.DataPanelId,
                          selectedInterval
                        )
                      }
                      disabledIntervals={[]}
                      trackStyles={{
                        error: {
                          backgroundColor: 'rgba(214,0,11,0.5)',
                          borderLeft: '1px solid rgba(214,0,11,0.5)',
                          borderRight: '1px solid rgba(214,0,11,0.5)',
                        },
                        valid: {
                          backgroundColor: 'rgba(98, 203, 102, 0.5)',
                          borderLeft: '1px solid #62CB66',
                          borderRight: '1px solid #62CB66',
                        },
                      }}
                      handleColors={{
                        error: 'rgb(214, 0, 11)',
                        valid: 'rgb(98, 203, 102)',
                      }}
                      containerClassName={classes.sliderContainer}
                    />
                  </Box>
                </Box>
              )}
              {
                <div style={fullScreenPanels.includes(dataPanel.DataPanelId) ? containerFullScreenStyle : containerStyle}>
                  <div
                    style={iframeContainerStyle}
                    id={`data-panel-${dataPanel.DataPanelId}`}
                  >
                    <iframe
                      title={`Dashboard Content ${dataPanel.DataPanelId}`}
                      src={buildUrl(dataPanel)}
                      style={iframeStyle}
                      key={`iframe-${dataPanel.DataPanelId}`}
                    ></iframe>
                  </div>
                </div>
              }
            </div>
          ))}
        </div>
      </>
    )
  );
};

const useStyles = makeStyles((theme) => ({
  sliderContainer: props => ({
    width: props.isMobile ? '90%' : '96%',
    marginTop: '20px',
    marginBottom: '30px',
  }),
}))

export default DashboardsPage;
